import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFollowReadScoreInfo, getFollowReadScoreList, getListenEnhanceInfo } from '@/api/listen';
import { ILInfoQuery } from './listeningSlice';

// 单句精听
export interface IListenState {
  /**
   * 0 显示音频，逐句播放；1 显示原文
   */
  showState: 0 | 1;
  /**
   * 0 隐藏；1 显示原句；2显示原句和翻译
   */
  showTranslateState: 0 | 1 | 2;
  /**
   * 显示正在播放句子的翻译
   */
  showPlayingTransitionState: boolean;
}

/**
 * 每句的信息
 */
export interface ISentenceInfo {
  cn_text: string;
  en_text: string;
  end_time: number;
  order: number;
  start_time: number;
}

/**
 * 句子的评测得分
 */
export interface ISentenceScore {
  id?: number;
  order?: number;
  file_url?: string;
  ref_text?: string;
  total_score: number; // 得分
  pron_fluency: number; // 流利度
  pron_accuracy: number; // 准确度
  pron_completion: number; // 完成度
}

/**
 * 评测时需要的参数
 */
export interface IFollowReadScoreQuery {
  paper_id: number;
  order: number;
  file_url: string; // 上传的MP3地址
  ref_text: string;
}

/**
 * 精听页数据
 */
export interface IEnhanceInfo {
  content: ISentenceInfo[];
  file_url: string;
  file_json_url: string;
  paper_id: number;
  title: string;
  topic: string[];
  type: string;
  type_td: string;
  unit: string;
  unit_id: any;
}
/**
 * 当前页的波形数据
 */
export interface IWaveData {
  audio_file: string;
  duration: number;
  sr: number;
  wave_data: number[];
}

export interface IListenWriteState {
  // currentTab: string;
  currentSentenceOrder: number;
  showAnswer: boolean;
  showTranslation: boolean;
  currentSubTab: string;
}

/**
 * 正在录制时，对应得句子信息
 */
export interface IRecordingInfo {
  id: number;
  order: number;
  file_url: string;
  ref_text: string;
}

/**
 * 跟读页面状态
 */
export interface IFollowReadState {
  recording: boolean;
  recordingInfo?: IRecordingInfo;
  waitingList: IRecordingInfo[];
}

export const getFollowReadScore = createAsyncThunk('listeningEnhace/getFollowReadScore', async (
  params: IFollowReadScoreQuery
) => {
  console.log('录音评测');
  const info = await getFollowReadScoreInfo(params);
  console.log('录音评测结果', info);
  const data: ISentenceScore = {
    id: params.paper_id,
    order: params.order,
    file_url: params.file_url,
    ref_text: params.ref_text,
    total_score: info.data.total_score,
    pron_fluency: info.data.pron_fluency,
    pron_accuracy: info.data.pron_accuracy,
    pron_completion: info.data.pron_completion
  };
  return data;
});
// 波形数据是否使用后端数据。
export const useBackendAudioWaveData = true;

export const initSentenceListen = createAsyncThunk('listeningEnhace/initSentenceListen', async (
  params: ILInfoQuery,
) => {
  const { id } = params;
  const info = await getListenEnhanceInfo(id, []); // 拉取当前页面信息

  const data = {
    lPaperInfo: info.data as IEnhanceInfo,
  } as any;

  const scoreList = await getFollowReadScoreList(id, []); // 拉取评测列表
  if (scoreList.data.list) {
    data.scoreList = scoreList.data.list;
  }

  return data;
});

export const initWaveDataFromBackend = createAsyncThunk('listeningEnhace/initSentencewaveData', async (params:{url: string;})=>{
  if (useBackendAudioWaveData && params.url) {
    const response = await fetch(params.url); // 拉取当前页面的波形数据
    const waveDate = await response.json();
    return waveDate as IWaveData;
  }
});

export interface IListeningEnhanceState {
  sentenceModule: IListenState;
  writingModule: IListenWriteState;
  followReadModule: IFollowReadState;
  lPaperInfo?: IEnhanceInfo;
  lLoading?: boolean;
  waveData?: IWaveData;
  scoreList?: ISentenceScore[];
}
const initialState: IListeningEnhanceState = {
  sentenceModule: { showState: 0, showTranslateState: 0, showPlayingTransitionState: false },
  writingModule: { currentSentenceOrder: 0, showAnswer: false, showTranslation: false, currentSubTab: '' },
  followReadModule: { recording: false, waitingList: [] },
  scoreList: [],
};
export const listeningEnhanceSlice = createSlice({
  name: 'listeningEnhace',
  initialState,
  reducers: {
    // 精听部分
    setListenShowState: (state, action: { payload: 0 | 1; }) => {
      state.sentenceModule.showState = action.payload;
    },
    setTranslateState: (state, action: { payload: 0 | 1 | 2; }) => {
      state.sentenceModule.showTranslateState = action.payload;
    },
    showPlayingTranslation: (state, action: { payload: boolean; }) => {
      state.sentenceModule.showPlayingTransitionState = action.payload;
    },
    setWaveData: (state, action: { payload: IWaveData; }) => {
      state.waveData = action.payload;
    },

    // 听写部分
    setListenWriteCurrentSubTab: (state, action: { payload: string; }) => {
      state.writingModule.currentSubTab = action.payload;
    },
    setListenWriteAnswerActive: (state, action: { payload: boolean; }) => {
      state.writingModule.showAnswer = action.payload;
    },
    setListenWriteTranslateActive: (state, action: { payload: boolean; }) => {
      state.writingModule.showTranslation = action.payload;
    },
    setListenWriteCurrentSentence: (state, action: { payload: number; }) => {
      if (
        state.lPaperInfo?.content &&
        action.payload < state.lPaperInfo?.content.length
      ) {
        state.writingModule.currentSentenceOrder = action.payload;
      }
    },
    setListenWriteCurrentSentencePre: (state) => {
      if (state.writingModule.currentSentenceOrder > 0) {
        state.writingModule.currentSentenceOrder =
          state.writingModule.currentSentenceOrder - 1;
      }
    },
    setListenWriteCurrentSentenceNext: (state) => {
      if (
        state.lPaperInfo?.content &&
        state.writingModule.currentSentenceOrder <
          state.lPaperInfo?.content.length - 1
      ) {
        state.writingModule.currentSentenceOrder =
          state.writingModule.currentSentenceOrder + 1;
      }
    },
    setResetListenWritingAction: (state) => {
      state.writingModule.showAnswer = false;
      state.writingModule.showTranslation = false;
    },
    // 跟读部分
    setRecordingAcitve: (
      state,
      action: { payload: Pick<IFollowReadState, 'recording' | 'recordingInfo'>; }
    ) => {
      if (state.followReadModule) {
        state.followReadModule.recording = action.payload.recording;
        state.followReadModule.recordingInfo = action.payload.recordingInfo;
      }
    },
    setScoringRecord: (state, action: { payload: IRecordingInfo; }) => {
      state.followReadModule.waitingList =
        state.followReadModule.waitingList.filter(
          (ele) => ele.order !== action.payload.order
        );
      state.followReadModule.waitingList.push(action.payload);
    },
    setScoringRecordFaild: (state, action: { payload: {order: number; }; }) => {
      state.followReadModule.waitingList =
        state.followReadModule.waitingList.filter(
          (ele) => ele.order !== action.payload.order
        );
    },
    setRecordingInactive: (state) => {
      state.followReadModule.recording = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initSentenceListen.pending, (state) => {
        state.lLoading = true;
      })
      .addCase(initSentenceListen.fulfilled, (state, action) => {
        if (action.payload) {
          state.lPaperInfo = action.payload.lPaperInfo;
          if (action.payload.scoreList) {
            state.scoreList = action.payload.scoreList;
          }
          state.lLoading = false;
          if (action.payload.lPaperInfo.content.length > 0) {
            state.writingModule.currentSentenceOrder =
              action.payload.lPaperInfo.content[0].order;
          }
        }
      })
      .addCase(initWaveDataFromBackend.fulfilled, (state, action) => {
        state.waveData = action.payload;
      })
      .addCase(getFollowReadScore.fulfilled, (state, action) => {
        if (action.payload) {
          const data = state.scoreList?.find(
            (value) => value.order === action.payload.order
          );
          if (data) {
            Object.assign(data, action.payload);
          } else {
            const data = { ...action.payload } as ISentenceScore;
            state.scoreList?.push(data);
          }
          state.followReadModule.waitingList =
            state.followReadModule.waitingList.filter(
              (ele) => ele.order !== action.payload.order
            );
        }
      })
      .addCase(getFollowReadScore.rejected, (state, action) => {
        const arg = action.meta.arg;
        const id = arg.paper_id;
        const order = arg.order;
        const file_url = arg.file_url;
        const ref_text = arg.ref_text;
        const data: ISentenceScore = {
          id,
          order,
          file_url,
          ref_text,
          total_score: 0,
          pron_fluency: 0,
          pron_completion: 0,
          pron_accuracy: 0,
        };
        const originData = state.scoreList?.find(
          (value) => value.order === order
        );
        if (!originData) {
          state.scoreList?.push(data);
        } else {
          originData.file_url = file_url;
        }

        state.followReadModule.waitingList =
          state.followReadModule.waitingList.filter(
            (ele) => ele.order !== order
          );
      });
  },
});

export const {
  setListenShowState,
  setTranslateState,
  showPlayingTranslation,
  setListenWriteCurrentSentence,
  setListenWriteTranslateActive,
  setListenWriteAnswerActive,
  setListenWriteCurrentSentencePre,
  setListenWriteCurrentSentenceNext,
  setRecordingAcitve,
  setRecordingInactive,
  setListenWriteCurrentSubTab,
  setWaveData,
  setScoringRecordFaild
} = listeningEnhanceSlice.actions; 

export const LisntentEnhanceActions = listeningEnhanceSlice.actions;
