import { Navigate, RouteProps } from 'react-router-dom';
import { withLazy } from '@/components/Lazy';
import { SWITCHES } from '@/utils/switches';
import { Redirect } from '@/modules/PageFramework/Redirect';
import { withLoginNotice } from '@/modules/Permissions/HOCs/withLoginNotice';
import { HomePage } from '../HomePage';


const LazyReadingList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-reading_list" */'@/modules/List/ReadingList').then((res) => res.ReadingList));
const LazyReadingDetail = withLazy(() => import(/* webpackChunkName: "lazy-ielts-reading_list" */'@/pages/Reading/sub-page/ReadingComprehension').then((res) => res.ReadingComprehension));
const LazyListeningList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-listening_list" */'@/modules/List/ListeningList').then(res => res.ListeningList));
const LazyWritingList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_list" */'@/modules/List/WritingList').then(res => res.App));
const LazyListeningDetail = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_list" */'@/pages/Listening/sub-page/ListeningQA').then(res => res.ListeningQA));
const LazyListeningEnhance = withLazy(() => import('@/pages/Listening/sub-page/ListeningEnhance').then(res => res.ListeningEnhance));

const LazyPracticeHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-practce_history_list" */'@/modules/List/HistoryList/PracticeHistory').then(res => res.PracticeHistory));
const LazyMockList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-mock_history_list" */'@/pages/Simulated/App').then(res => res.App));
const LazySampleEssayHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-sample_essay_history_list" */'@/modules/List/HistoryList/SampleEssayHistory').then(res => res.SampleEssayHistory));
const LazyWritingTask2EvaluationHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-evaluation_2_history_list" */'@/modules/List/HistoryList/WritingTask2EvaluationHistory').then(res => res.WritingTask2EvaluationHistory));
const LazyWritingTask1EvaluationHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-evaluation_1_history_list" */'@/modules/List/HistoryList/WritingTask1EvaluationHistory').then(res => res.WritingTask1EvaluationHistory));
const LazyMockTestHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-mock_test_list" */'@/modules/List/HistoryList/MockTestHistory').then(res => res.MockTestHistory));
const LazyModelEssay = withLazy(() => import(/* webpackChunkName: "lazy-ielts-sample_essay" */'@/modules/ModelEssay').then(res => res.ModelEssay));
const LazyWritingEvaluation = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_task_2_evaluation" */'@/modules/WritingEvaluation/Task2Evaluation').then(res => res.WritingEvaluation));
const LazyWritingTask1Evaluation = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_task_1_evaluation" */'@/modules/WritingEvaluation').then(res => res.WritingEvaluation));
const LazyTrainingList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-list" */'@/modules/List/TrainingList').then(res => res.TrainingList));
const LazyTrainingCollectionList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-collection-list" */'@/modules/List/TrainingCollectionList').then(res => res.TrainingCollectionList));
const LazyBasicTrainingHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-history" */'@/modules/List/HistoryList/BasicTraining').then(res => res.BasicTrainingHistory));
const LazyColletionHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-history" */'@/modules/List/HistoryList/Collection').then(res => res.ColletionHistory));
const LazyAIToolsHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-history" */'@/modules/List/HistoryList/AIToolsHistory').then(res => res.AIToolsHistory));
const LazyBasicTrainingCollection = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-collection" */'@/pages/BasicTrainingV2/sub-page/Collection').then(res => res.BasicTrainingCollectionQuestion));

/** v2 版本的小作文自定义题目  */
const LazyWritingTask1EvaluationV2 = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-collection" */'@/modules/WritingEvaluation/Task1Evaluation').then(res => res.WritingTask1Evaluation));
const LazyWritingFeedbackTask1EvaluationV2 = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-collection" */'@/modules/WritingEvaluation/Task1Evaluation').then(res => res.WritingTask1Evaluation));

/** v2 版本大作文批改，自定义题目和选题用的是一个页面 */
const LazyWritingTask2EvaluationNextV2 = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-collection" */'@/modules/WritingEvaluation/Task2Evaluation/Next/Task2EvaluationNext').then(res => res.Task2EvaluationNext));

export const routers: RouteProps[] = [
  { path: '/', Component: HomePage },
  // AI 工具

  /** @deprecated */
  { path: '/big-essay/:recordId?', Component: withLoginNotice(LazyWritingEvaluation, '#/writing-task-2') },
  /** @deprecated */
  { path: '/small-essay/:id?', Component: withLoginNotice(LazyWritingTask1Evaluation, '#/writing-task-1') },
  /** @deprecated */
  { path: '/small-essay/:id/:recordId?', Component: withLoginNotice(LazyWritingTask1Evaluation, '#/writing-task-1') },

  /** 小作文列表 */
  { path: '/writing-task-1', Component: LazyWritingTask1Evaluation },

  /** 小作文自定义题目 */
  { path: '/writing-task-1/:id?/:recordId?', Component: withLoginNotice(LazyWritingTask1EvaluationV2, '#/writing-task-1') },

  /** 小作文批改 */
  // { path: '/writing-task-2/:recordId?', Component: withLoginNotice(LazyWritingEvaluation, '#/writing-task-2') },

  /** v2 大作文批改 */
  { path: '/writing-task-2/:recordId?', Component: withLoginNotice(LazyWritingTask2EvaluationNextV2, '#/writing-task-2') },

  { path: '/writing-feedback-task-2/:recordId?', Component: withLoginNotice(LazyWritingTask2EvaluationNextV2, '#/writing-task-2') },
  { path: '/writing-feedback-task-1', Component: LazyWritingTask1Evaluation },
  { path: '/writing-feedback-task-1/:id/:recordId?', Component: withLoginNotice(LazyWritingFeedbackTask1EvaluationV2, '#/writing-task-1') },

  { path: '/model-essay', Component: LazyModelEssay },
  { path: '/model-essay-result', Component: LazyModelEssay },

  // 基础练习
  { path: '/training-list/:type?', Component: SWITCHES.BasicTrainingCollection ? LazyTrainingCollectionList : LazyTrainingList },
  { path: '/training-collection/:collectionId', Component: LazyBasicTrainingCollection },
  { path: '/training-collection/:collectionId/:collectionRecordId', Component: LazyBasicTrainingCollection },

  /** 强化练习写作列表 */
  { path: '/writing-list', element: <Navigate to='/writing-list/1' replace /> },

  /** 强化练习写作 */
  { path: '/writing-list/:module_id', Component: LazyWritingList },

  /** 强化练习阅读列表 */
  { path: '/reading-list', Component: LazyReadingList },

  /** 强化练习阅读详情 */
  { path: '/reading-next/:id', Component: LazyReadingDetail },

  /** 强化练习听力 */
  { path: '/listening-list', Component: LazyListeningList },

  /** 强化练习听力详情 */
  { path: '/listening-next/:id', Component: LazyListeningDetail },

  /** 精听 */
  { path: '/listening-enhance/:id', Component: LazyListeningEnhance },

  // 模考
  { path: '/mock_list', Component: LazyMockList },

  // 历史记录
  { path: '/history/practice/', Component: LazyPracticeHistory },
  { path: '/history/practice/:type', Component: LazyPracticeHistory },
  { path: '/history/mock_test', Component: LazyMockTestHistory },
  { path: '/history/sample_essay', Component: LazySampleEssayHistory },
  { path: '/history/evaluation', Component: LazyWritingTask2EvaluationHistory },
  { path: '/history/essay', Component: LazyWritingTask1EvaluationHistory },
  { path: '/history/basic_training', Component: LazyBasicTrainingHistory },
  { path: '/history/collection-record', Component: LazyColletionHistory },
  { path: '/history/ai-tools-record', Component: LazyAIToolsHistory },
  { path: '/history', Component: LazyPracticeHistory },
  { path: '/*', Component: Redirect },
];