import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useMemoizedFn } from 'ahooks';
import { AIAvatarIcon } from '@/components/Icon/AIAvatar';
import { SendIcon } from '@/components/Icon/SendIcon';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import { useAppSelector } from '@/stores';
import i18n from '@/locales/init';
import { getHotProblemsFromHomePage } from '@/api/ai';
import { LoginModal, PermisssionModal } from '@/modules/Permissions';
import lessModule from './section-ai.module.less';

const LazyAIModal = () => import(/* webpackChunkName: "lazy-ielts-normal_ai_chat_modal" */'./AIModal').then((res) => res.AIModal);

export const SectionAI = () => {
  const isLoginUser = useAppSelector(state => state.user.userPermission.isLogin);
  const canIUseAI = useAppSelector(state => !!(state.user.userPermission.ai?.isVip || state.user.userPermission.ai?.freeTime !== 0));

  const [problems, setProblems] = useState<string[]>([]);
  const [syncList, setSyncList] = useState<boolean>(true);

  const handleSyncList = useMemoizedFn(() => {
    getHotProblemsFromHomePage().then((res) => {
      if (res.code === 200) {
        const l = res.data.ai_problem.map((ele) => ele.content).slice(0, 9);
        setProblems(l);
      }
    }).finally(() => {
      setSyncList(false);
    });
  });

  const handleOpenAIModal = useMemoizedFn((content: string) => {
    if (!isLoginUser) {
      LoginModal.open();
      return;
    }

    if (!canIUseAI) {
      PermisssionModal.open();
      return;
    }

    LazyAIModal().then((open) => {
      open(content);
    });
  });

  useEffect(() => {
    handleSyncList();
  }, []);

  return <>
    <div className={lessModule.ai_problems}>

      <SimpleButton
        data-track-key={'home-sectionai-update'}
        icon={<SyncOutlined style={{ marginRight: '1rem' }} spin={syncList} />}
        className={lessModule.ai_problems_update}
        onClick={() => { setSyncList(true); handleSyncList(); }}
      >
        {i18n.t('common.dauyan_change_some')}
      </SimpleButton>

      <div className={lessModule.ai_problems_title}>{i18n.t('common.dauyan_hello_iam_uyan')}</div>
      <div className={lessModule.ai_problems_title}>{i18n.t('common.dauyan_frequently_questions')}</div>
      <div className={lessModule.ai_problems_body}>{
        problems.length > 0 ?
          problems.map((pro) => {
            return (
              <div
                className={lessModule.ai_problem_item}
                key={pro}
                data-content={pro}
                ata-track-key={'home-sectionai-modalwithproblem'}
                onClick={() => handleOpenAIModal(pro)}>
                {pro}
              </div>
            );
          })
          : <><div /><div><br /></div><div /><div /><Spin /><div /><div /><div><br /></div><div /></>
      }</div>
    </div>
    <div className={lessModule.ai_input} data-track-key={'home-sectionai-openmodal'} onClick={() => handleOpenAIModal('')}>
      <div className={lessModule.ai_input_text}>{i18n.t('ai.input_placeholder')}</div>
      <div className={lessModule.ai_input_icon}><SendIcon /></div>
    </div>
  </>;
};

export const SectionAIClassName = lessModule.section;
