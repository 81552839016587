import React from 'react';
import { Modal } from 'antd';
import i18n from '@/locales/init';
import { login } from '../../utils/login';

let loginModalVisible = false;

export const LoginModal = {
  open: (cancel?: () => void, config?: { title: string; content: React.ReactNode; okText: string; cancelText: string; getUrl: () => string; }) => {
    if (loginModalVisible) {
      return;
    }
    loginModalVisible = true;
    // 弹窗中断的情况下，清理选区
    if (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA') {
      (document.activeElement as (HTMLInputElement | HTMLTextAreaElement)).blur();
    }
    const title = config?.title || i18n.t('common.dauyan_account_invalid_before_login');
    const content = config?.content || '';
    const okText = config?.okText || i18n.t('common.dauyan_account_login');
    const cancelText = config?.cancelText || i18n.t('common.dauyan_cancel');
    Modal.confirm({
      title,
      content,
      okText,
      cancelText,
      cancelButtonProps: {
        'data-track-key': 'global-login-cancel'
      },
      okButtonProps: {
        style: { outline: 'none', },
        'data-track-key': 'global-login'
      },
      onCancel: () => {
        loginModalVisible = false;
        if (cancel) {
          cancel();
        }
      },
      onOk() {
        loginModalVisible = false;
        login(config?.getUrl);
      },
    });
  },
};

export const gotoLoginPage = () => {
  login();
};
