/* eslint-disable indent */
import React, { FC, useMemo } from 'react';
import { Button, ButtonProps } from 'antd';
import classnames from 'classnames';
import css from './simple.button.module.less';

export interface SimpleButtonProps extends ButtonProps {
  theme: { primaryColor: string; 
    reverseColor: string; 
    textColor?: string; 
    primary: boolean; 
    hoverColor?: string; 
  };
}

export type SimpleButtonType = 'link' | 'text' | 'default' | 'primary' | 'dashed' | undefined

/**
 * 
 * @param props 
 * @returns 
 */

export const SimpleButtonWithTheme: FC<SimpleButtonProps> = (props) => {
  const style: any = useMemo(() => {
    const btnColor = props.theme.primary ? props.theme.primaryColor : props.theme.reverseColor;
    const hoverColor = {
      '#1D77FF': '#1D70ED',
      '#11AA66': '#0C9156',
      '#FF8400': '#EE7B00',
      '#FFFFFF': '#f9f9f9',
    }[btnColor.toUpperCase()] || btnColor;
    return {
      '--btn-color': btnColor,
      '--btn-text-color': props.theme.textColor ? props.theme.textColor : props.theme.primary ? props.theme.reverseColor : props.theme.primaryColor,
      '--btn-border-color': props.theme.primaryColor,
      '--btn-hover-color': hoverColor,
      ...props.style || {},
    };
  }, [props.theme, props.style]);


  return <Button
    id={props.id}
    {...props}
    className={classnames(css.btn, props.className && props.className )}
    style={style}
  />;
};