import jsCookie from 'js-cookie';
import { logCount, logError, logInfo } from '@/utils/logger/arms-config';
import store, { batch } from '@/stores';
import { fetchTouristInfo, fetchUserInfo, fetchUserPermissions, fetchUserPoints, setTokenAbmormal } from '@/stores/slices/userSlice';
import { login } from './login';

interface AuthState {
  token: string;
  status: 'success' | 'fail';
  isReAuth?: boolean;
}

interface AuthConfig {
  needGetUserInfo?: boolean;
  forceLogin?: boolean;
}

export function auth(config?: AuthConfig): Promise<AuthState> {
  const forceLogin = config?.forceLogin ?? false;
  const needGetUserInfo = config?.needGetUserInfo ?? false;

  if (!forceLogin) {
    const token = jsCookie.get('token');
    if (token) {
      if (needGetUserInfo) {
        fetchUserInfo();
      }
      return Promise.resolve({
        token,
        status: 'success',
        isReAuth: false
      });
    } else {
      logInfo({ type: 'login', position: 'check auth', token: false, forceLogin });
    }
  } else {
    logInfo({ type: 'login', position: 'check auth', token: false, forceLogin });
  }

  login();

  // 兼容 ts 类型
  return Promise.resolve({
    token: '',
    status: 'success',
    isReAuth: false
  });

}

export default auth;

// 不要求登录的页面
export const noAuthWhiteList = [
  '/simulated_ielts/',
  '/',
];


export async function updateUserInfo() {
  try {
    if (!jsCookie.get('token')) {
      await store.dispatch(fetchTouristInfo());
      await store.dispatch(fetchUserInfo());
      await updateUserPermission();
      return;
    }
    await store.dispatch(fetchUserInfo());
    await updateUserPermission();
  } catch (e) {
    logError(e as any);
    logCount('Get_User_Info_Failed');
  }
}

export async function updateUserPermission() {
  try {
    await batch(() => {
      store.dispatch(fetchUserPermissions());
      store.dispatch(fetchUserPoints());
    });
  } catch (e) {
    logError(e as any);
    logCount('Get_User_Permission_Failed');
  }
}

export function setTokenInvalid() {
  store.dispatch(setTokenAbmormal());
}
