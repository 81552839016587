import Axios from 'axios';

import { AxiosResponseType } from '@/utils/network/axios-helper';
import type { ToolsBarMarkValue } from '@/modules/RichTextEditor/QuillModules/reading-toolsbar/blots/toolsBarMark';

export const getPaperInfo = async (id: number, type?: number[]): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/reading/get-paper-info', { id, type: type || [] });
};

export const getRecordEssayDraft = async (record_id: number, type: number): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/reading/get-record-essay-draft', { record_id, type });
};

export const getRecordInfo = async (record_id: number): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/reading/get-record-info', { id: record_id });
};

export const getRecordAnswerInfo = async (record_id: number) => {
  return Axios.post('/api/reading/get-record-answer-info', { record_id });
};

export const readingComprehensionCollateAnswer = async (params: any) => {
  return Axios.post('/api/reading/submit-collate-answer', params);
};


export const readingComprehensionSaveRecord = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/reading/save-record', params);
};


export const readingSaveMarks = async (type: 1 | 2, recordId: number, list: ToolsBarMarkValue[]): Promise<AxiosResponseType<any>> => {

  const listData = list.map(item => {

    return {
      range: {
        index: item.index,
        length: item.length,
      },
      quote_content: item.quoteContent,
      flag: item.type,
      local_id: item.id,
      instance_id: item.quillId,
      local_timestamp: item.localTimestamp,
    };
  });

  return Axios.post('/api/reading/save-record-mark-draft', {
    type,
    record_id: recordId,
    list: listData
  });
};


export interface ISaveNoteReq {
  list: NoteRef[];
  record_id: number;
  // 草稿位置（1、正文区 2、答题区）
  type: 1 | 2;
}

export interface NoteRef {
  contents?: NoteItem[];
  instance_id: string;
  local_id: string;
  local_timestamp: number;
  quote_content?: string;
  range: {
    index: number;
    length: number;
  };
}

export interface NoteItem {
  content: string;
  local_id: string;
  local_timestamp?: number;
  pid: string;
}

export const readingComprehensionSaveNote = async (params: ISaveNoteReq): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/reading/save-record-note-draft', params);
};


export interface IOneWordTranslate {
  src: string;
  dst: string;
  src_tts: string;
  dst_tts: string;
  common: {
    from: string;
    text: string;
  };
  coreTags: string[];
  parts: Array<{
    part: string;
    means: string[];
  }>;
  phAm: string;
  phEn: string;
}

export const translateApi = async (params: {
  content: string;
  isWord: boolean;
}): Promise<{
  longTranslateContent: string;
  oneWordTranslate: IOneWordTranslate;
}> => {
  const res = await Axios.post<{
    content: string;
    type: number;
    dict: {
      dict: string; // json
      src: string;
      src_tts: string;
      dst: string;
      dst_tts: string;
    };
  }>('/api/reading/translate', {
    content: params.content,
    type: params.isWord ? 1 : 2,
  });

  let parts = [];
  let coreTags: string[] = [];
  let phAm = '';
  let phEn = '';
  const common = {
    from: '',
    text: '',
  };

  try {
    parts = JSON.parse(res.data.dict.dict)?.word_result?.simple_means?.symbols[0]?.parts || [];
    phAm = JSON.parse(res.data.dict.dict)?.word_result?.simple_means?.symbols[0]?.ph_am || '';
    phEn = JSON.parse(res.data.dict.dict)?.word_result?.simple_means?.symbols[0]?.ph_en || '';
    coreTags = JSON.parse(res.data.dict.dict)?.word_result?.simple_means?.tags?.core || [];
  } catch (e) {
    // console.log('parse error', e);
  }


  return {
    longTranslateContent: res.data.content,
    oneWordTranslate: {
      src: res?.data?.dict?.src,
      dst: res?.data?.dict?.dst,
      src_tts: res?.data?.dict?.src_tts,
      dst_tts: res?.data?.dict?.dst_tts,
      common,
      coreTags,
      parts,
      phAm,
      phEn,
    },
  };

};


export interface ITranslatesReqListType {
  // 翻译内容
  content: string;
  // 翻译词典信息
  dict?: any;

  instance_id: string;
  range?: {
    index: number;
    length: number;
  };
  // 1单词 2句子
  trans_type?: number;
}

export const saveTranslatesApi = async (params: {
  record_id: number;
  list: ITranslatesReqListType[];
}): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/reading/save-record-translate-draft', {
    record_id: params.record_id,
    list: params.list,
    // 固定为 1
    type: 1,
  });
};

export const saveCoreIdeaApi = async (params: {
  record_id: number;
  content: string;
}) => {
  return Axios.post('/api/reading/save-exam-anchor', {
    record_id: params.record_id,
    content: params.content,
  });
};


export const getCoreIdeaApi = async (params: {
  record_id: number;
}) => {
  return Axios.post('/api/reading/get-exam-anchor', {
    record_id: params.record_id,
  });
};


