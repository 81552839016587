import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import classNames from 'classnames';
import { ConfigProvider, DatePicker, Form, Modal, Select, Spin, Row, Col, Button } from 'antd';
import { useMemoizedFn } from 'ahooks';
import toast from 'react-hot-toast';

import zh_CN from 'antd/locale/zh_CN';
import { chunk } from 'lodash';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import i18n, { Lang, getLanguage } from '@/locales/init';
import store, { useAppSelector } from '@/stores';
import { updateUserInfoApi } from '@/api/user/user';
import { fetchUserInfo } from '@/stores/slices/userSlice';
import lessModule from './section.module.less';

import 'dayjs/locale/zh-cn';


// 设置 moment 语言
dayjs.locale('zh-cn');
dayjs.extend(utc);

const DAY_MS = 60 * 60 * 1000 * 24;
const DATE_FORM = 'YYYY.MM.DD';

const getToday = () => {
  const d = Date.now();
  return d - d % DAY_MS;
};

const ExamHistoryList = [
  { value: 0, text_key: '-' },
  { value: 1, text_key: 'common.dauyan_yes' },
  { value: 2, text_key: 'common.dauyan_no' },
];

const ExamPurposeList = [
  { value: 0, text_key: '-' },
  { value: 1, text_key: 'common.dauyan_study_abroad' },
  { value: 2, text_key: 'common.dauyan_migrate_or_word' },
  { value: 3, text_key: 'common.dauyan_self_enhance' },
  { value: 4, text_key: 'common.dauyan_hobby' },
];

const TargetScoreList = [
  { value: 6, text_key: 'common.dauyan_feedback_score_1' },
  { value: 6.5, text_key: 'common.dauyan_feedback_score_1' },
  { value: 7, text_key: 'common.dauyan_feedback_score_1' },
  { value: 7.5, text_key: 'common.dauyan_feedback_score_1' },
  { value: 8, text_key: 'common.dauyan_feedback_score_1' },
];

export const SectionTarget = () => {
  const userSettings = useAppSelector(state => state.user.userSettings);
  const userInfoInital = useAppSelector(state => state.user.userInfoInital);
  const isStoredUser = useAppSelector(state => state.user.userPermission.isLogin);
  const [editing, setEditing] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const dayToDeadline = useMemo(() => {
    const t = getToday();
    if (userSettings.examTime < t) {
      return 0;
    } else {
      return Math.ceil((userSettings.examTime - t) / DAY_MS);
    }
  }, [userSettings.examTime]);

  const list = useMemo(() => {
    return [
      {
        key: 'target_score',
        text_key: 'common.dauyan_profile_target_score',
        value: userSettings.targetScore.targetScore,
        display_value: isStoredUser && userInfoInital && userSettings.targetScore.targetScore ? i18n.t('common.dauyan_feedback_score', { score: userSettings.targetScore.targetScore }) : '-',
        display_class_name: lessModule.displayItemValue_G,
        editor: { type: 'option', options: TargetScoreList },
        update_keys: ['target_score', 'target_score_listen', 'target_score_read', 'target_score_speak', 'target_score_write'],
        disabled: false,
      },
      {
        key: 'exam_purpose',
        text_key: 'common.dauyan_exam_purpose',
        value: userSettings.examPurpose,
        display_value: i18n.t(ExamPurposeList[userSettings.examPurpose].text_key) || '-',
        display_class_name: lessModule.displayItemValue,
        editor: { type: 'option', options: ExamPurposeList },
        update_keys: ['exam_purpose'],
        disabled: false,
      },
      {
        key: 'exam_time',
        text_key: 'common.dauyan_plan_exam_time',
        value: userSettings.examTime,
        display_value: userSettings.examTime ? dayjs.utc(userSettings.examTime).format(DATE_FORM) : '-',
        display_class_name: lessModule.displayItemValue_G,
        editor: { type: 'date', defaultValue: userSettings.examTime || getToday() + DAY_MS },
        update_keys: ['exam_date'],
        disabled: false,
      },
      {
        key: 'student_level',
        text_key: 'common.dauyan_current_study_grade',
        value: 0,
        display_value: '-',
        display_class_name: lessModule.displayItemValue_dis,
        editor: { type: 'option', options: [] },
        update_keys: [''],
        disabled: true,
      },
      {
        key: 'exam_history',
        text_key: 'common.dauyan_have_taken_ielts',
        value: userSettings.examHistory,
        display_value: i18n.t(ExamHistoryList[userSettings.examHistory].text_key) || '-',
        display_class_name: lessModule.displayItemValue,
        editor: { type: 'option', options: ExamHistoryList },
        update_keys: ['is_ielts'],
        disabled: false,
      },
      {
        key: 'student_location',
        text_key: 'common.dauyan_region',
        value: 0,
        display_value: '-',
        display_class_name: lessModule.displayItemValue_dis,
        editor: { type: 'option', options: [] },
        update_keys: [''],
        disabled: true,
      }
    ];
  }, [userSettings, isStoredUser, userInfoInital]);

  const handleUpdateValue = useMemoizedFn((result) => {
    if (updating) {
      return;
    }
    setUpdating(true);
    const req: any = {};
    list.forEach((ele) => {
      if (result[ele.key]) {
        if (ele.editor.type === 'option') {
          ele.update_keys.forEach((k) => {
            req[k] = result[ele.key].value;
          });
        } else if (ele.editor.type === 'date') {
          ele.update_keys.forEach((k) => {
            req[k] = result[ele.key].valueOf();
          });
        }
      }
    });

    updateUserInfoApi(req).then(() => {
      return store.dispatch(fetchUserInfo()).unwrap();
    }).then(() => {
      toast.success(i18n.t('common.dauyan_update_successful'));
    }).catch(() => {
      toast.error(i18n.t('common.dauyan_update_failed'));
    }).finally(() => {
      setEditing(false);
      setUpdating(false);
    });

  });

  const handleEdit = useMemoizedFn(() => {
    if (!updating && !editing && isStoredUser) {
      setEditing(true);
    }

  });

  const _list = chunk(list, 2);

  return (
    <div className={classNames(lessModule.section_target_container)}>

      <div className={lessModule.header}>
        <div className={lessModule.header_left}>
          <div className={lessModule.title}>
            {i18n.t(i18n.t(i18n.t('common.dauyan_exam_info')))}
          </div>
          {
            isStoredUser ?
              <div className={lessModule.titleScript}>
                <span>{i18n.t(i18n.t('common.dauyan_distance_of_exam_time'))}</span>
                <span className={lessModule.titleScript_R}>{dayToDeadline}</span>
                <span>{i18n.t(i18n.t('common.dauyan_distance_of_exam_time_af'))}</span>
              </div> : null
          }
        </div>
        <div className={lessModule.header_right}>
          <div className={classNames(lessModule.editBtn, updating || editing || !isStoredUser ? lessModule.editBtn_dis : '')} onClick={handleEdit}>{i18n.t(i18n.t('common.dauyan_edit'))}</div>
        </div>
      </div>

      <div className={lessModule.body}>
        {
          _list.map((ele, idx) => {
            return (
              <Row key={idx} gutter={24} className={classNames(lessModule.section_target_row)}>
                {
                  ele.map(d => {
                    return (
                      <Col key={d.key} span={12}>
                        <Row>
                          <Col span={10} className={classNames(lessModule.section_target_label)}>
                            {i18n.t(d.text_key)}:
                          </Col>

                          <Col span={14}>
                            {d.display_value}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })
                }
              </Row>
            );
          })
        }
      </div>


      {
        editing ? <Modal
          open
          footer={null}
          title={<div className={lessModule.modalHeader}>{i18n.t(i18n.t(i18n.t('common.dauyan_exam_info')))}</div>}
          className={lessModule.modalWrapper}
          closable
          maskClosable
          onCancel={() => setEditing(false)}
          width={600}
        >
          <ConfigProvider >
            <Form onFinish={handleUpdateValue} >
              {
                updating ? <div className={lessModule.loading}><Spin /></div> : null
              }
              {
                !updating ?
                  <>
                    <div className={lessModule.editBody}>
                      {
                        list.map((ele) => {
                          return <div key={ele.key} className={lessModule.editItem}>
                            <div className={lessModule.editItemLabel}>{i18n.t(ele.text_key)}</div>
                            <div className={lessModule.editItemEditor}>
                              <ConfigProvider locale={getLanguage() === Lang.zhCn ? zh_CN : undefined}>
                                <Form.Item required name={ele.key}>
                                  {
                                    ele.editor.type === 'date' ?
                                      <DatePicker
                                        defaultValue={dayjs.utc(ele.editor.defaultValue || getToday() + DAY_MS)}
                                        style={{ width: '100%' }}
                                        showToday={false}
                                        format={DATE_FORM}
                                        allowClear={false}
                                        disabledDate={(current) => {
                                          return current && current < dayjs().endOf('day');
                                        }}
                                      />
                                      : null
                                  }
                                  {
                                    ele.editor.type === 'option' ?
                                      <Select
                                        disabled={ele.disabled}
                                        placeholder="-"
                                        defaultValue={ele.value || undefined}
                                        labelInValue
                                        options={(ele.editor.options || []).filter((ele) => ele.value).map((op) => ({ value: op.value, label: i18n.t(op.text_key, { value: op.value }) }))}
                                      />
                                      : null
                                  }
                                </Form.Item>
                              </ConfigProvider>
                            </div>
                          </div>;
                        })
                      }
                    </div>
                    <div className={lessModule.formFooter}>
                      <Form.Item>
                        <Button data-track-key={'home-sectiontarget-confirm'} type="primary" htmlType='submit' >{i18n.t(i18n.t('common.dauyan_confirm'))}</Button>
                      </Form.Item>
                    </div>
                  </>
                  : null
              }

            </Form>
          </ConfigProvider>
        </Modal> : null
      }
    </div >
  );
};

export const SectionTargetClassName = lessModule.wrapper;
