import React from 'react';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import css from './index.module.less';

export const MenuTrigger = React.memo((props: any) => {
  return (
    <div className={classnames(css['menu_trigger'])}>
      <div className={classnames(css['menu_trigger_btn'])}>
        {props.status ? <RightOutlined /> : <LeftOutlined />}
      </div>
    </div>
  );
});


export const menuTriggerStyle: React.CSSProperties = {
  zIndex: 10,
  width: '12px',
  right: '-10px',
};
