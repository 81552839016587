import { CSSProperties } from 'react';
import classnames from 'classnames';
import { gotoNewPage } from '@/utils/gotoNewPage';
import { homeUrl } from '@/utils/env';
import { useAppSelector } from '@/stores';
import css from './index.module.less';

const image = 'https://duy-ielts-public.oss-cn-hangzhou.aliyuncs.com/front/dauyan_brand.png';
const image_mini = 'https://duy-ielts-public.oss-cn-hangzhou.aliyuncs.com/front/dauyan_brand_mini.png';

export const HeaderLogo = (props: { style?: CSSProperties; className?: string; }) => {
  const show_menu = useAppSelector(state => state.ui.home.showMenu);
  return (
    <div className={classnames(css['header_logo_container'], !show_menu && css['header_logo_container_mini'])}
      onClick={() => {
        gotoNewPage(homeUrl);
      }}>
      <img className={classnames(css['header_logo'], props.className)} style={props.style} alt='' src={show_menu ? image : image_mini} />
    </div>
  );
};
