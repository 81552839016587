import { createAsyncThunk, createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import { logCount, logError } from '@/utils/logger/arms-config';
// import { LocalStorage } from '@/utils/persist/local-storage';
import { getCourseInfo } from '@/api/course/get-course-info';
import { ICourseWithoutSectionType, ILessonInfo, ILessonMetaType, ISectionProgressType, ISectionType } from '@/modules/Course/course.types';

import { filterRemoteCourseInfo, remoteCourseInfoToLocalCourseInfo, remoteLessonInfoToLocalLessonInfo, remoteLessonProgressToLocalLessonProgress, remoteSectionListToLocalSectionList } from '@/modules/Course/utils/remoteCourseInfoToLocalCourseInfo';

import { getLessonRecordInfo } from '@/api/course/get-record-info';
import { getLessonInfo } from '@/api/course/get-lesson-info';

// const CourseRecord = 'CourseRecord';

interface ICourseState{
  id?: number;
  hasError: boolean;
  courseInfo?: ICourseWithoutSectionType;
  sections: ISectionType[];
  flatternLessons: ILessonMetaType[];
  sectionsProgress: ISectionProgressType[];
  currentLesson?: ILessonInfo;
  currentLessonId?: number;
  currentSectionId?: number;
  isPreview: boolean;
  forceUpdateFlag: number;
  forceUpdateCourseFlag: number;
}
const initialState: ICourseState = {
  hasError: false,
  sections: [],
  flatternLessons: [],
  sectionsProgress: [],
  isPreview: false,
  forceUpdateFlag: 0,
  forceUpdateCourseFlag: 0,
};

export const getCourseInfoAsy = createAsyncThunk('course/getCourseInfoAsy', async (
  params: { id: string|number; }, { rejectWithValue }
) => {
  try {
    const { id } = params;
    const info = await getCourseInfo({
      id: Number(id),
    });
    if (info.data) {
      return filterRemoteCourseInfo(info.data);
    }
    logCount('Course Info Error');
    rejectWithValue(null);
  } catch (e) {
    logCount('Course Info Error');
    rejectWithValue(null);
    logError(e as any);
  }
});

export const getSectionsProgessAsy = createAsyncThunk('course/getSectionsProgessAsy', async (
  params: { id: number[]; }, { getState, rejectWithValue }
) => {
  try {

    const { id } = params;
    const info = await getLessonRecordInfo({
      id: id
    }); // 
    if (info.data) {
      return info.data;
    }
    rejectWithValue(null);
  } catch (e) {
    logError(e as any);
    rejectWithValue(null);
  }
});

export const getLessonInfoAsync = createAsyncThunk('course/getLessonInfo', async (
  params: { id: number; }, { rejectWithValue }
) => {
  try {
    const { id } = params;
    const info = await getLessonInfo({ id });
    if (info.data) {
      return info.data;
    }
    rejectWithValue(null);
  } catch (e) {
    logError(e as any);
    rejectWithValue(null);
  }
});

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setPreview: (state, action: { payload: boolean; }) => {
      state.isPreview = action.payload;
    },
    forceUpdate: (state) => {
      state.forceUpdateFlag = state.forceUpdateFlag + 1;
    },
    forceUpdateCourse: (state) => {
      state.forceUpdateCourseFlag = state.forceUpdateCourseFlag + 1;
    },
    clearLessonInfo: (state) => {
      state.currentLesson = undefined;
      state.currentLessonId = undefined;
      state.currentSectionId = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLessonInfoAsync.rejected, (state, action) => {
        state.hasError = true;
      })
      .addCase(getLessonInfoAsync.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.currentLesson = remoteLessonInfoToLocalLessonInfo(action.payload);
        state.currentSectionId = state.sections.find((sec) => sec.lessons.find((les) => les.id === action.payload!.id))?.id;
        state.currentLessonId = action.payload.id;
      })
      .addCase(getCourseInfoAsy.rejected, (state, action) => {
        state.hasError = true;
      })
      .addCase(getCourseInfoAsy.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        const courseInfo = remoteCourseInfoToLocalCourseInfo(action.payload);
        const sections = remoteSectionListToLocalSectionList(action.payload.lesson_list);
        const list: ILessonMetaType[] = [];
        sections.forEach((s) => {
          s.lessons.forEach(l => { list.push(l); });
        });

        if (list.length > 0 && !courseInfo.learningProgress.lastLesson) {
          courseInfo.learningProgress.lastLesson = list[0].id;
        }

        state.flatternLessons = list;
        if (state.id !== courseInfo.id) {
          state.sectionsProgress = [];
        }
        state.courseInfo = courseInfo;
        state.sections = sections;
        state.id = courseInfo.id;
        if (state.currentLesson) {
          state.currentSectionId = sections.find((sec) => sec.lessons.find((les) => les.id === state.currentLesson?.id))?.id;
        }
      })
      .addCase(getSectionsProgessAsy.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        const list = remoteLessonProgressToLocalLessonProgress(action.payload.list);
        list.forEach(item => {
          const il = state.sectionsProgress.find((s) => s.id === item.id);
          if (il){
            il.lastProgress = item.lastProgress;
            il.finished = item.finished;
            il.total = item.total;
          } else {
            state.sectionsProgress.push(item);
          }
        });
      });
  }
});


// export const courseSliceMiddleWare = createListenerMiddleware();
// courseSliceMiddleWare.startListening({
//   predicate: (action, currentState, originalState) => {
//     const nextCourseId = (currentState as any).course.id;
//     const nextLessonId = (currentState as any).currentLessonId;
//     const prevCourseId = (originalState as any).course.id;
//     const prevLessonId = (originalState as any).currentLessonId;

//     return !!nextCourseId && !!nextLessonId && (nextCourseId !== prevCourseId || nextLessonId !== prevLessonId);
//   },
//   effect: (action, listenerApi) => {
//     const state = listenerApi.getState();
//     const stored: { [key: number]: number; } = LocalStorage.getStorage(CourseRecord) || {};
//     const courseId = (state as any).course.id;
//     const lessonId = (state as any).currentLessonId;
//     stored[courseId] = lessonId;
//     LocalStorage.setStorage(CourseRecord, stored);
//   },
// });

export const CourseActions = courseSlice.actions;
