import jsCookie from 'js-cookie';
import { ieltsLastWeb } from '../env';

const whiteList = ['mobile-writing-upload-image'];

export const checkVersion = () => {
  if (whiteList.some((s) => window.location.pathname.includes(s))) {
    return;
  }
  const v = jsCookie.get('VERSION');
  if (v === 'v0') {
    window.location.href = ieltsLastWeb;
  }
};
