import { post } from '@/utils/network/axios-helper';

export interface IGetLessonInfo_Res{
  id: number;
  name: string;
  cover_path: string;
  desc: string;
  /** 学习次数 */
  click_num: number;
  /** 创建时间 */
  create_time: number;
  /**
   * 总时长
   */
  duration: number;
  height: number;
  play_url: string;
  /**
   * 当前观看时长
   */
  seen_duration: number;
  /**
   * 已观看总时长
   */
  seen_total: number;
  status: number;
  width: number;

  exam_type: number;


  /** 关联资源的id */
  resource_id: number;
  /** 作对的题数 弃 */
  exam_correct: number;
  /** 总题数 弃 */
  exam_total: number;
  /** 关联的record_id */
  exam_record_id: number;
  /** 耗时 */
  exam_use_time: number;
  rate: string;

  /** 基础练习题目数量 */
  question_num: number;

  /** 口语 */

  part_name: string;
  topic_name: string;
  /** APP 扫码使用的二维码 */
  qrcode_url: string;

  /** 评分 */
  grammar: number;
  vocabulary: number;
  proficient_score: number;
  pron_fluency: number;
  pron_accuracy: number;
}


/**
 * 获取单节课，后端section和lesson的概念和前端正好相反
 * @param params 
 * @returns 
 */
export const getLessonInfo = async (params: { id: number; }) => {
  return await post<IGetLessonInfo_Res>('/api/course/get-section-info', params);
};