import React, { useMemo } from 'react';
import { useMemoizedFn } from 'ahooks';
import { Tooltip } from 'antd';
import { logError } from '@/utils/logger/arms-config';
import { usePreventRouteChange } from '@/hooks/usePreventRouteChange';
import i18n, { getLanguage, Lang, LANG_MAP, setLanguage } from '@/locales/init';
import lessModule from './toggle.module.less';

export const ToggleLanguage = React.memo(() => {
  const blocker = usePreventRouteChange();

  const currentLang = useMemo(() => {
    return getLanguage();
  }, []);

  const handleClick = useMemoizedFn(() => {
    blocker().then((v) => {
      if (!v) {
        return;
      }
      const n = LANG_MAP.find((ele) => ele.value !== currentLang);
      if (!n) {
        logError(`[Language Switch Failed]: Map: ${JSON.stringify}, current: ${currentLang}`);
        return;
      }
      setLanguage(n.value);

      window.location.reload();
    });

  });

  return (
    <Tooltip title={i18n.t('中文 | English')}>
      <div onClick={handleClick} data-track-key='home-language' className={lessModule.toggle}>
        <div data-active={currentLang === Lang.zhCn} className={lessModule.text}>中</div>
        <div className={lessModule.split}></div>
        <div data-active={currentLang === Lang.enUs} className={lessModule.text}>EN</div>
      </div>
    </Tooltip>
  );

});
