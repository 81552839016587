import { HtmlHTMLAttributes } from 'react';
import './index.less';
import classNames from 'classnames';
import { size } from 'lodash-es';

interface IAITeacherIconProps extends HtmlHTMLAttributes<HTMLDivElement> {
  size?: number;
  disabled?: boolean;
}
export const AITeacherIcon = (props: IAITeacherIconProps)=>{
  return <div {...props} data-disabled={props.disabled} className={classNames('ai-teacher-icon', props.className)} style={{width: props.size, height: props.size}}></div>;
};

