/* eslint-disable no-lone-blocks */
import './init/runtime-var';

// 是否是本地
export const isRemote: boolean = ((window as any).runtime.env === 'production');

// 版本
export const version: string = (window as any).runtime.version;
// 测试 | 线上
// TODO: 地址需要做一次确认
export const isProduction: boolean = (window as any).location.origin.endsWith('dauyan.com');

export const debugMode = window.location.href.indexOf('debug=1') !== -1;

const currentHost = (window as any).location.href as string;

export const taobaoUrl = 'https://shop435855621.taobao.com/';

export let authUrl: string;
export let homeUrl: string;
export let authCookieDomain: string;
export let payUrl: string;
export let socketHOST: string;
export let shareHost: string;
export let ieltsHost: string;
export let ieltsWeb: string;
export let ieltsTestWeb: string;

export let ieltsLastWeb: string;

{
  if (currentHost.includes('localhost') || currentHost.includes('127.0.0.1')) {
    authUrl = 'http://localhost:3100/';
    authCookieDomain = 'localhost';
    payUrl = 'https://pay.dauyan.net/';
    socketHOST = 'ws://118.31.63.26:3000';
    homeUrl = 'https://www.dauyan.net/';
    shareHost = 'http://localhost:3000/';
    ieltsHost = 'localhost';
    ieltsWeb = 'http://localhost:3000/';
    ieltsTestWeb = 'https://simulate.dauyan.net';
    ieltsLastWeb = 'http://localhost:3000/';
  } else if (currentHost.includes('.net')) {
    authUrl = 'https://account.dauyan.net/';
    authCookieDomain = '.dauyan.net';
    payUrl = 'https://pay.dauyan.net/';
    socketHOST = `wss://${window.location.host}`;
    homeUrl = 'https://www.dauyan.net/';
    shareHost = 'https://share.dauyan.net/';
    ieltsHost = 'ielts.dauyan.net';
    ieltsWeb = 'https://ielts.dauyan.net/';
    ieltsTestWeb = 'https://simulate.dauyan.net';
    ieltsLastWeb = 'https://v1.dauyan.net/';
  } else {
    authUrl = 'https://account.dauyan.com/';
    authCookieDomain = '.dauyan.com';
    payUrl = 'https://pay.dauyan.com/';
    socketHOST = `wss://${window.location.host}`;
    homeUrl = 'https://www.dauyan.com/';
    shareHost = 'https://share.dauyan.com/';
    ieltsHost = 'ielts.dauyan.com';
    ieltsWeb = 'https://ielts.dauyan.com/';
    ieltsTestWeb = 'https://simulate.dauyan.com';
    ieltsLastWeb = 'https://v1.dauyan.com/';
  }
}

export const externalLink = {
  // 版权声明
  copyright: {
    'zh-CN': `${homeUrl}link/zh-CN/copyright-statement.html`,
    'en-US': `${homeUrl}link/en-US/copyright-statement.html`,
  },
  // 隐私协议
  privacyPolicy: {
    'zh-CN': `${homeUrl}link/zh-CN/disclaimer.html`,
    'en-US': `${homeUrl}link/en-US/disclaimer.html`,
  },
  // 免责声明
  disclaimer: {
    'zh-CN': `${homeUrl}link/zh-CN/disclaimer.html`,
    'en-US': `${homeUrl}link/en-US/disclaimer.html`,
  },
  // 用户协议
  userAgreement: {
    'zh-CN': `${homeUrl}link/zh-CN/user-agreement.html`,
    'en-US': `${homeUrl}link/en-US/user-agreement.html`,
  },
};
