import { Modal } from 'antd';
import i18n from '@/locales/init';
import lessModule from './notice.module.less';

export const BasicTrainingCollectionBreakNotice = {
  open: (props: { callback: (v: boolean) => void; }) => {
    const m = Modal.confirm({
      width: 480,
      icon: null,
      bodyStyle: {
        padding: '12px 8px',
      },
      wrapClassName: lessModule.wrap,
      title: i18n.t('你确定要退出吗？'),
      content: <div style={{ height: '106px', paddingTop: '8px' }}>{i18n.t('退出后，将不会保留当前的做题记录。')}</div>,
      centered: true,
      closable: true,
      maskClosable: true,
      okButtonProps: {
        type: 'default',
        danger: true,
      },
      cancelButtonProps: {
        type: 'primary',
        style: {
          marginLeft: '8px'
        },
      },
      cancelText: i18n.t('留下做题'),
      okText: i18n.t('确认退出'),
      onCancel: (e) => {
        props.callback(false);
        m.destroy();
      },
      onOk: () => {
        console.log('[OK]');
        props.callback(true);
        m.destroy();
      },
    });
  },
};

