import type { ITopicItem } from '@/api/writing/topicList2';

export const gotoWritingModule = async (item: ITopicItem, module_id: number, searchInfo?: string, viewRecord?: number)=>{
  console.log('跳转到指定模块',item, module_id);
  let url = '';
  switch (module_id) {
    case 1:
      url = `/question-review/${item.id}/1?search=${searchInfo}`;
      break;
    case 2:
      url = `/writing-ideas/${item.id}/1?search=${searchInfo}`;
      break;
    case 3:
      url = `/segmented/${item.id}/1?search=${searchInfo}`;
      break;
    case 4:
      switch (item.step) {
        case 1:
        default:
          url = `/question-review/${item.id}/2?search=${searchInfo}`;
          break;
        case 2:
          url = `/writing-ideas/${item.id}/2?search=${searchInfo}`;
          break;
        case 3:
          url = `/segmented/${item.id}/2?search=${searchInfo}`;
          break;
      }
      break;
    case 5:
      url = `/sample-essay/${item.id}${item.record_id && item.status === 1 ? `/${item.record_id}` : ''}`;
      break;
    case 6:
      // url = `/evaluation/${item.id}${item.record_id && item.status === 1 ? `/${item.record_id}` : ''}`;
      if (viewRecord) {
        url = `/evaluation/${item.id}/${item.record_id}`;
      } else {
        if (!item.record_id || item.status === 3){
          url = `/evaluation/${item.id}`;
        } else {
          url = `/evaluation/${item.id}/${item.record_id}`;
        }
      }
      break;
    case 7:
      // url = `/essay/${item.id}${item.record_id && item.status === 1 ? `/${item.record_id}` : ''}`;
      if (viewRecord) {
        url = `/essay/${item.id}/${item.record_id}`;
      } else {
        if (!item.record_id || item.status === 3){
          url = `/essay/${item.id}`;
        } else {
          url = `/essay/${item.id}/${item.record_id}`;
        }
      }
      break;
    default:
      return;
  }

  gotoNewPage(window.location.origin + '/writing/#' + url);
};

export const gotoWritingList = (id: number) => {
  gotoNewPage(window.location.origin + '/#/' + id);
};

export const gotoNewPage = (url: string) => {
  // window.location.href = url;
  window.open(url);
};