import { useEffect } from 'react';
import i18n from '@/locales/init';
import store, { useAppSelector } from '@/stores';
import { LoginModal } from '../components/LogInModal';

export const usePageEntryLoginNotice = (defaultPath?: string) => {
  const userInited = useAppSelector(state => state.user.userInfoInital);
  useEffect(() => {
    if (!userInited) {
      return;
    }
    const isLogin = store.getState().user.userPermission.isLogin;
    if (!isLogin) {
      console.log('login');
      LoginModal.open(() => {}, {
        title: i18n.t('登录提醒'),
        content: i18n.t('您当前为游客模式，练习数据将不会被保留。为保证您的数据一致性，建议您完成登录/注册'),
        okText: i18n.t('去登录'),
        cancelText: i18n.t('继续练习'),
        getUrl: () => `${window.location.origin}${window.location.pathname}${window.location.search}${defaultPath ? defaultPath : window.location.hash}`
      });
    }
  }, [userInited]);
};
