import { IGetLessonInfo_Res } from '@/api/course/get-lesson-info';
import type { ICourseInfo_Res, LessonList, SectionList } from '@/api/course/get-course-info';
import i18n from '@/locales/init';
import type { Progress } from '@/api/course/get-record-info';
import { ICourseWithoutSectionType, ILessonInfo, ISectionProgressType, ISectionType } from '../course.types';

// 过滤前端有效的课程类型
export const filterRemoteCourseInfo = (remote: ICourseInfo_Res): ICourseInfo_Res => {
  const lessonList: ICourseInfo_Res['lesson_list'] = [];
  const result: ICourseInfo_Res = {
    detail: remote.detail,
    lesson_list: lessonList,
    progress: {
      ...remote.progress,
    },
  };

  let total = 0;

  remote.lesson_list.forEach((ele) => {
    const l: SectionList[] = ele.section_list.filter((sele) => {
      return sele.exam_type === 1 || sele.exam_type === 2 || sele.exam_type === 6;

    });


    lessonList.push({
      lesson_id: ele.lesson_id,
      name: ele.name,
      section_list: l,
    });
    total += l.length;
  });


  result.progress.total = total;
  return result;
};

// 课程数据类型转换
export const remoteCourseInfoToLocalCourseInfo = (remote: ICourseInfo_Res): ICourseWithoutSectionType => {
  const result: ICourseWithoutSectionType = {
    id: remote.detail.id,
    name: remote.detail.name,
    author: i18n.t('大宇言官方课程'),
    desc: remote.detail.introduction,
    detailDesc: remote.detail.details,
    lessonCount: remote.progress.total,
    cover: remote.detail.cover_path,
    price: remote.detail.original_price,
    salePrice: remote.detail.price,
    buyUrl: remote.detail.url,
    purchased: remote.detail.is_purchase === 2,
    doneNum: remote.detail.done_num,
    studyNum: remote.detail.click_num,
    learningProgress: {
      lastLesson: remote.progress.section_id,
      finished: remote.progress.finished === remote.progress.total,
      finishedLesson: remote.progress.finished,
      total: remote.progress.total
    },
  };
  return result;
};

// section 转换
export const remoteSectionListToLocalSectionList = (remote: LessonList[]): ISectionType[] => {
  return remote.map((sec) => {
    return {
      id: sec.lesson_id,
      name: sec.name,
      lessons: sec.section_list.map((les) => {
        return {
          id: les.section_id,
          name: les.name,
          type: les.exam_type,
          isFree: les.is_free === 1,
          count: les.question_num,
          part: les.part_name,
          topic: les.topic_name,
        };
      }),
    };
  });
};

export const remoteLessonProgressToLocalLessonProgress = (remote: Progress[]): ISectionProgressType[] => {
  return remote.map((pr) => {
    return {
      id: pr.id,
      finished: pr.status === 2,
      total: pr.total,
      lastProgress: pr.seen_duration,
    };
  });
};

export const remoteLessonInfoToLocalLessonInfo = (remote: IGetLessonInfo_Res): ILessonInfo => {
  return {
    id: remote.id,
    type: remote.exam_type,
    name: remote.name,
    desc: remote.desc,
    status: remote.status,
    createTime: remote.create_time,
    visitTime: remote.click_num,
  
    // source
    // url: remote.play_url,
    // duration: remote.duration,
    // width: remote.width,
    // height: remote.height,

    // progress: remote.seen_total,
    // lastProgress: remote.seen_duration,

    video: remote.exam_type === 1 ? {
      // source
      url: remote.play_url,
      cover: remote.cover_path,
      duration: remote.duration,
      width: remote.width,
      height: remote.height,

      progress: remote.seen_total,
      lastProgress: remote.seen_duration,
    } : undefined,
    basic: remote.exam_type === 2 ? {
      collectionId: remote.resource_id,
      collectionRecordId: remote.exam_record_id,
      usedTime: remote.exam_use_time,
      count: remote.question_num,
      rate: Number(remote.rate),
    } : undefined,
    speaking: remote.exam_type === 6 ? {
      usedTime: remote.exam_use_time,
      qrUrl: remote.qrcode_url,
      part: remote.part_name,
      topic: remote.topic_name,
      grammar: remote.grammar,
      vocabulary: remote.vocabulary,
      proficient_score: remote.proficient_score,
      pron_fluency: remote.pron_fluency,
      pron_accuracy: remote.pron_accuracy,
    } : undefined,
  };
};
