
import jsCookie from 'js-cookie';
import { AxiosResponseType, post } from '@/utils/network/axios-helper';
import { logError } from '@/utils/logger/arms-config';
import { debugMode, isProduction } from '@/utils/env';
import { fetchEventSource } from './fetchEventSource';
// 提问websocket
export const wsAIHelper = '/ws/homepage/ai-helper';

// 获取全局ai历史

export interface IAIItem {
  id: number;
  content: string;
  ai_content: string;
  create_time: number;
}

export interface IGetAIHelperListRes {
  list: IAIItem[];
}

export const getAIHelperList = (id: number, pageSize = 10): Promise<AxiosResponseType<IGetAIHelperListRes>> => {
  return post<IGetAIHelperListRes>('/api/homepage/get-ai-record-list', {
    id,
    page_size: pageSize,
  });
};

export interface IGetHotProblems_Req {
  content?: string;
}

export interface IGetHotProblems_Res {
  list: string[];
}

export const getHotProblems = (data: IGetHotProblems_Req) => {
  return post<IGetHotProblems_Res>('/api/homepage/get-ai-problem-list', data);
};

export interface IGetHotProblemsFromHomePage_Res {
  ai_problem: Array<{ content: string; }>;
}

export const getHotProblemsFromHomePage = () => {
  return post<IGetHotProblemsFromHomePage_Res>('/api/homepage/get-homepage-info', {});
};


export const save_ai_record_info = async (payload: any) => {
  return post('/api/basic-training/save-ai-record-info', payload);
};

export interface I_get_ai_record_list_Res {
  list: any[];
}

export const get_ai_record_list = async (payload: any): Promise<AxiosResponseType<I_get_ai_record_list_Res>> => {
  return post('/api/basic-training/get-ai-record-list', payload);
};

/** 请求 ai 的解析 */
export const request_ai_analysis_stream = async (payload: any, cb: (request_data: any, stream_data: any) => void) => {
  const token = jsCookie.get('token');
  const url = '/api/basic-training/http-ai-helper';

  await fetchEventSource(url, {
    body: JSON.stringify(payload),
    headers: {
      'authorization': token || '',
    },
    method: 'POST',
    onerror: (error: any) => {
      console.log('>>>>>> error', error);
    },
    onmessage: (ev: any) => {
      process_chunk_data(payload, ev.data, cb);
    },
    onopen: async (res: any) => {
      console.log('>>>>>> onopen', res);
    },
    signal: null,
  });
};


/** 请求 ai 的解析 */
export const request_ai_analysis_stream_v2 = async (payload: any, cb: (request_data: any, stream_data: any) => void) => {
  const token = jsCookie.get('token');
  const url = '/api/basic-training/v1/http-ai-helper';

  await fetchEventSource(url, {
    body: JSON.stringify(payload),
    headers: {
      'authorization': token || '',
    },
    method: 'POST',
    onerror: (error: any) => {
      console.log('>>>>>> error', error);
      logError(`[API Error]: ${url}, request: ${JSON.stringify(payload)}, token: ${token}`);
    },
    onmessage: (ev: any) => {
      process_chunk_data(payload, ev.data, cb);
    },
    onopen: async (res: any) => {
      console.log('>>>>>> onopen', res);
    },
    signal: null,
  });
};

/** 处理流式数据 */
const process_chunk_data = async (request_data: any, chunk: string, cb: (request_data: any, stream_data: any) => void) => {
  const str_list = chunk.trim().split('data:').filter((l) => !!l);
  try {
    if (!isProduction || debugMode) {
      console.log('[Str]', str_list);
    }
    const json_data = str_list.map(s => s.trim()).map((s) => {
      try {
        return JSON.parse(s);
      } catch (e) {
        return null;
      }
    }).filter((l) => !!l);

    cb(request_data, json_data);
  } catch (err) {
    console.log('Error', err);
    cb(request_data, { 'code': 200, 'msg': 'end', 'run_id': '', 'data': { 'record_id': 0, 'score': 0, 'evaluation': '', 'content': 'Some Error happened' } });
  }
};
