import React from 'react';
import { usePageEntryLoginNotice } from '../hooks/usePageEntryLoginNotice';


export const withLoginNotice = (Comp: React.ElementType<any>, defaultPath?: string) => {
  return React.memo((props: any) => {
    usePageEntryLoginNotice(defaultPath);

    return <Comp {...props} />;
  });
};
