import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { get_lms_list } from 'src/api/common';
import { LmsSection as LmsSectionItem } from './LmsSection';

export const LmsSection = () => {
  const [sections, set_sections] = useState<any>([null, null, null, null]);

  const get_lms_list_hadnler = async () => {
    try {
      const res = await get_lms_list() as any;
      if (res.code === 200) {
        const list: any[] = res.data.list || [];
        const mapping = new Map();
        list.forEach((d, i) => {
          mapping.set(i, d);
        });
        set_sections(list);
      }
    } catch (err) { }
  };

  useEffect(() => {
    get_lms_list_hadnler();
  }, []);

  return (
    <Row gutter={24}>
      {sections.map((item: any, idx: number) => {
        return (
          <Col xs={12} md={12} lg={6} xl={6} xxl={4} key={idx}>
            <LmsSectionItem item={item} idx={idx + 1} />
          </Col>
        );
      })}
    </Row>
  );
};