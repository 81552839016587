import { createSlice } from '@reduxjs/toolkit';
import type { AudioPlayerProps } from '@/modules/AudioPlayer/player';

interface AudioPlayerProp extends AudioPlayerProps {
  audioDecoding?: boolean;
  audioDecodeFailed?: boolean;
}

const initialState: AudioPlayerProp = {
  audioDecoding: false,
  audioDecodeFailed: false,
  progressText: '00:00/00:00',
};
export const audioPlayerSlice = createSlice({
  name: 'audioPlayer',
  initialState,
  reducers: {
    /**
     * 设置播放器状态
     * @param state 
     * @param action 
     * @returns 
     */
    setState: (state, action: { payload: AudioPlayerProps; }) => {
      return { ...state, ...action.payload as typeof state };
    },
    setDecoding: (state, action: { payload: boolean; }) => {
      state.audioDecoding = action.payload;
    },
    setDecodeFailed: (state, action: { payload: boolean; }) => {
      state.audioDecodeFailed = action.payload;
    },
    /**
     * 只设置播放或暂停
     * @param state 
     * @param action 
     */
    setPlayState: (state, action: { payload: boolean; }) => {
      state.playState = action.payload;
    },

    reset: (state)=>{
      state = initialState;
    },

    setAll: (state, action: { payload: AudioPlayerProps; }) => {
      return action.payload;
    },
  }
});
export const AudioPlayerActions = audioPlayerSlice.actions;
