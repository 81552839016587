import classNames from 'classnames';
import { Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import { HistoryOnHomePage } from '@/modules/List/HistoryList/HistoryOnHomePage';
import i18n from '@/locales/init';
import lessModule from './index.module.less';
import { SectionUser, UserSectionClassName } from './SectionUser';
import { SectionAI } from './SectionAI';
import { SectionTarget } from './SectionTarget';
import { SectionCurrent } from './SectionCurrent';
import { LmsSection } from './LmsSection';

export const HomePage = () => {
  return (
    <>
      <Helmet title={'大宇言AI雅思 - 学习中心'} />
      <div className={lessModule.wrapper}>
        <div className={lessModule.row}>
          <section className={classNames(lessModule.section, UserSectionClassName)}>
            <SectionUser />
          </section>
        </div>

        <div className={classNames(lessModule.media_row)}>
          <div className={classNames(lessModule.row)}>
            <LmsSection />
          </div>

          <div className={classNames(lessModule.section_next, lessModule.media_section_next)}>
            <Row gutter={24} className={classNames(lessModule.media_section_next_row)}>
              <Col span={12}>
                <SectionTarget />
              </Col>
              <Col span={12}>
                <SectionCurrent />
              </Col>
            </Row>
          </div>
        </div>

        {/* <div className={classNames(lessModule.row)}>
          <section style={{ paddingTop: 0 }}>
            <HistoryOnHomePage title={i18n.t('common.dauyan_home_page_history')}></HistoryOnHomePage>
          </section>
        </div>

        <div className={classNames(lessModule.row)}>
          <section style={{ paddingTop: 0 }}>
            <HistoryOnHomePage type='writing' title={i18n.t('common.dauyan_home_page_writing_history')}></HistoryOnHomePage>
          </section>
        </div> */}

        <div className={lessModule.row}>
          <section className={classNames(lessModule.section)}><SectionAI /></section>
        </div>
      </div >
    </>
  );
};
