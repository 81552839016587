import { log } from 'node:console';
import React, { useEffect, useState } from 'react';
import { Layout, theme } from 'antd';
import classnames from 'classnames';
import { Route, Routes, } from 'react-router';
import { useMemoizedFn } from 'ahooks';
import { scaleWindow } from '@/utils/scaleWindow';
import { useAppDispatch, useAppSelector } from '@/stores';
import { fetchMenus, UIAction } from '@/stores/slices/uiSlice';
import { routers } from './Router/index';
import { Menu } from './Menu';
import { HomeHeader } from './HomeHeader';
import { MenuTrigger } from './Menu/MenuTrigger';
import css from './layout.module.less';

const { Header, Content, Sider } = Layout;

scaleWindow();

const App: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const showMenu = useAppSelector(state => state.ui.home.showMenu);
  const menu = useAppSelector(state => state.ui.home.menu);

  const dispatch = useAppDispatch();

  const init = useMemoizedFn(() => {
    dispatch(fetchMenus());
  });

  useEffect(() => {
    init();
  }, []);

  const toggleMenu = useMemoizedFn(() => {
    dispatch(UIAction.setHomeMenuVisible(!showMenu));
  });

  const collapsed = !showMenu;
  const [collapsed_input, set_collapsed_input] = useState<number>(0);


  // 初始化时收起菜单记录状态
  useEffect(() => {
    if (collapsed_input === 0 && collapsed && menu.length > 0) {
      set_collapsed_input(1);
    }
  }, [collapsed, menu, collapsed_input]);

  return (
    <Layout className={classnames(css['home'])}>
      <Layout className={classnames(css['home_body'])}>
        <Header style={{ padding: 0, background: colorBgContainer }} className={css['home-header']} >
          <HomeHeader />
        </Header>
        <Layout hasSider={true}>
          <Sider
            theme='light'
            collapsed={collapsed}
            collapsible
            collapsedWidth={50}
            trigger={<MenuTrigger status={!showMenu} />}
            onCollapse={(collapsed, type) => {
              toggleMenu();
            }}
          >
            <Menu showMenu={showMenu} display={collapsed_input} />
          </Sider>

          <Content className={classnames(css['home-content'])}>
            <Routes>
              {
                routers.map((item) => {
                  return <Route key={item.path} {...item} />;
                })
              }
            </Routes>
          </Content>
        </Layout>

      </Layout>
    </Layout>
  );
};

export default App;