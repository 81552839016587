import classNames from 'classnames';
import { useMemoizedFn } from 'ahooks';
import { useNavigate } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { MockIcon } from '@/components/Icon/MockIcon';
import i18n from '@/locales/init';
import { useAppSelector } from '@/stores';
import { login } from '@/modules/Permissions';
import { getLastMockTestScore } from '@/api/history';
import lessModule from './section.module.less';

const SIZE = 300;

export const SectionCurrent = () => {
  const isStoredUser = useAppSelector(state => state.user.userPermission.isLogin);
  const target_score = useAppSelector(state => state.user.userSettings.targetScore.targetScore);
  const [backgroundImgUrl, setBackgroundImgUrl] = useState<string>('');

  const canvaseRef = useRef<HTMLCanvasElement | null>(null);


  const [currentScore, setCurrentScore] = useState(-1);
  const [hasTestHistory, setHasTestHistory] = useState(false);
  const scoreDis = Math.max(0, target_score - currentScore);


  useEffect(() => {
    if (!isStoredUser) {
      setHasTestHistory(false);
      setCurrentScore(-1);
      return;
    }
    getLastMockTestScore().then((res) => {
      if (res.code === 200) {

        setCurrentScore(res.data.score);
        if (res.data.score !== -1) {
          setHasTestHistory(true);
        }
      }
    }).catch(() => {
      setHasTestHistory(false);
      setCurrentScore(-1);
    });
  }, [isStoredUser]);

  const handleDraw = useMemoizedFn(() => {
    if (!canvaseRef.current) {
      canvaseRef.current = document.createElement('canvas');
    }
    const context = canvaseRef.current.getContext('2d');
    if (!context) {
      return;
    }
    // canvaseRef.current.width = devicePixelRatio * SIZE;
    // canvaseRef.current.height = devicePixelRatio * SIZE;
    canvaseRef.current.width = SIZE;
    canvaseRef.current.height = SIZE;

    const w = devicePixelRatio * SIZE;
    const h = devicePixelRatio * SIZE;
    const s = 40 * devicePixelRatio;
    const a = Math.PI * 2 * currentScore / 9;

    if (!hasTestHistory) {
      context.fillStyle = 'transparent';
      context.fillRect(0, 0, w, h);

      context.setTransform(1 / devicePixelRatio, 0, 0, 1 / devicePixelRatio, 0, 0);
      context.lineWidth = s;
      context.strokeStyle = '#EEEEEE';
      context.ellipse(w / 2, h / 2, Math.min(w / 2 - s / 2, h / 2 - s / 2), Math.min(w / 2 - s / 2, h / 2 - s / 2), 0, 0, Math.PI * 2, true);
      context.stroke();
      context.resetTransform();
    } else {
      context.fillStyle = 'transparent';
      context.fillRect(0, 0, w, h);
      context.setTransform(1 / devicePixelRatio, 0, 0, 1 / devicePixelRatio, 0, 0);
      context.lineWidth = s;
      context.strokeStyle = '#EEEEEE';
      context.ellipse(w / 2, h / 2, Math.min(w / 2 - s / 2, h / 2 - s / 2), Math.min(w / 2 - s / 2, h / 2 - s / 2), -Math.PI / 2, 0, a, true);
      context.stroke();

      context.lineWidth = s;
      context.strokeStyle = '#1D77FF';
      context.beginPath();
      context.closePath();
      context.ellipse(w / 2, h / 2, Math.min(w / 2 - s / 2, h / 2 - s / 2), Math.min(w / 2 - s / 2, h / 2 - s / 2), -Math.PI / 2, a, Math.PI * 2, true);
      context.stroke();

      context.resetTransform();
    }

    canvaseRef.current.toBlob((b: Blob | null) => {
      if (b) {
        setBackgroundImgUrl(URL.createObjectURL(b));
      }
    });
  });

  useEffect(() => {
    handleDraw();
  }, [hasTestHistory, scoreDis]);

  const navigate = useNavigate();
  const handleMockTest = useMemoizedFn(() => {
    navigate('/mock_list');
  });

  return (
    <div className={classNames(lessModule.section_current_container)}>
      <div className={lessModule.header}>
        <div className={lessModule.header_left}>
          <div className={lessModule.icon}><MockIcon /></div>
          <div className={lessModule.title}>
            {i18n.t('common.dauyan_mock_test_score')}
          </div>
          {
            isStoredUser && hasTestHistory && !!target_score ?
              <div className={lessModule.titleScript}>
                <span>{i18n.t('common.dauyan_distance_of_target_score')}</span>
                <span className={lessModule.titleScript_R}>{scoreDis}</span>
                <span>{i18n.t('common.dauyan_distance_of_target_score_af')}</span>
              </div> : null
          }
        </div>
      </div>
      <div className={lessModule.body}>
        <div style={{
          backgroundImage: `url(${backgroundImgUrl})`,
          width: '100%',
          height: '220px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'inherit'
        }} className={lessModule.chart}>
          <div className={lessModule.textWrapper}>
            {
              isStoredUser ?
                hasTestHistory ? <>
                  <div className={lessModule.textScore}>{currentScore.toFixed(1)}</div>
                  <div className={lessModule.textScript}>{i18n.t('common.dauyan_latest_test_score')}</div>
                </> : <>
                  <div className={lessModule.textScript}>{i18n.t('common.dauyan_no_score_temporarily')}</div>
                  <div className={lessModule.textAction} onClick={handleMockTest}>{i18n.t('common.dauyan_go_to_mock_test')}</div>
                </>
                : <div className={lessModule.textAction} onClick={() => login()}>{i18n.t('common.dauyan_go_login')}</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export const SectionCurrentClassName = lessModule.wrapper;
