import React from 'react';
import classnames from 'classnames';
import jsCookie from 'js-cookie';
import { useMemoizedFn } from 'ahooks';
import { Modal } from 'antd';
import { authCookieDomain, ieltsLastWeb } from '@/utils/env';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import { AIAvatarIcon } from '@/components/Icon/AIAvatar';
import { HeaderLogo } from '@/components/Icon/HeaderLogo';
import { SimpleButtonWithTheme } from '@/components/Simple/SimpleButtonWithTheme';
import { UserInfo } from '@/modules/UserInfo/lazy';
import { UIAction } from '@/stores/slices/uiSlice';
import store, { useAppSelector } from '@/stores';
import i18n from '@/locales/init';
import { ToggleLanguage } from '@/modules/International/components/ToggleLanguage';
import { usePreventRouteChange } from '@/hooks/usePreventRouteChange';
import home_slot_map from './HomeSlotMap';
import home_breadcrumb_map from './HomeBreadcrumbMap';
import css from './home.header.module.less';

export const HomeHeader = () => {
  const rightUrl = useAppSelector(state => state.ui.home.rightButton);
  const showAI = useMemoizedFn(() => {
    store.dispatch(UIAction.setBasicTrainingShowAI(true));
  });

  const blocker = usePreventRouteChange();

  /** forceUpdate */
  const _ = useAppSelector(state => state.ui.home.is_replace_header_force_update);

  /** 是否替换全部的 header */
  const is_replace_header_children = useAppSelector(state => state.ui.home.is_replace_header_children);
  /** 使用当前的 header 渲染 */
  const is_replace_header_slot = useAppSelector(state => state.ui.home.is_replace_header_slot);

  /** 是否替换全部的 left 部分 */
  const is_replace_header_left_children = useAppSelector(state => state.ui.home.is_replace_header_left_children);
  /** 使用当前的 left 部分替换 */
  const is_replace_header_left_slot = useAppSelector(state => state.ui.home.is_replace_header_left_slot);

  const switchToOldVersion = useMemoizedFn(() => {
    blocker().then(v => {
      if (!v) {
        return;
      }
      Modal.confirm({
        closable: true,
        maskClosable: true,
        title: i18n.t('确认要切换到旧版吗？'),
        content: i18n.t('切换后将无法使用新版功能，未来所有新功能将只在新版进行优化。'),
        cancelText: i18n.t('留在新版'),
        cancelButtonProps: {
          type: 'primary',
        },
        onCancel: () => {
          console.log('cancel');
        },
        okButtonProps: {
          type: 'default',
        },
        okText: i18n.t('返回旧版'),
        onOk: () => {
          console.log('ok');
          jsCookie.set('VERSION', 'v0', { domain: authCookieDomain });
          window.location.href = ieltsLastWeb;
        },
        className: css.switchModal,
      });
    });
  });

  return (
    <div className={classnames(css['container'])}>
      {is_replace_header_children ?
        home_slot_map.get(is_replace_header_slot) :
        <>
          <HeaderLogo />
          <div className={classnames(css['left'])}>
            {is_replace_header_left_children && home_breadcrumb_map.get(is_replace_header_left_slot)}
          </div>
          <div className={classnames(css['right'])}>
            {
              rightUrl.map((s) => {
                if (s === 'ai') {
                  return <SimpleButton key={s} className={css['ai-button']} style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }} data-track-key="basic-training-ai-entry" icon={<AIAvatarIcon />} onClick={showAI}>{i18n.t('有问题可以问我')}</SimpleButton>;
                }
                return null;
              })
            }
            <ToggleLanguage />
            {
              rightUrl.length === 0 ? <SimpleButtonWithTheme data-track-key="switch-version-old" onClick={switchToOldVersion} theme={{
                primaryColor: '#DCDCDC',
                reverseColor: '#FFFFFF',
                textColor: '#000000',
                primary: false,
                hoverColor: undefined
              }}>{i18n.t('返回旧版')}</SimpleButtonWithTheme> : null
            }
            <UserInfo />
          </div>
        </>
      }
    </div>
  );
};