import { initTracking, login, logout, logEvent, logBeconEvent, logPV, setConfig } from '@dauyan/h5-tracking';
import jsCookie from 'js-cookie';
import { isRemote, isProduction, version } from '../env';
import { getPageName } from './get-page-name';


const APPID = 20005152;

export const setLoggerUserId = (uid?: string) => {
  if (uid) {
    login(uid);
  } else {
    logout();
  }
};

export const logBDEvent = logEvent;
export const logBDBeconEvent = logBeconEvent;

let lastPage = '';

export const logBDPage = () => {
  const p = getPageName();
  if (p === lastPage) {
    return;
  }
  lastPage = p;
  setConfig({ page_key: p, biz_version: 'v2' });
  logPV({
    page_key: p,
    biz_version: 'v2',
  });

};

export const resetUserInfo = () => {
  logout();
};

const setEnv = () => {
  setConfig({ environment: isRemote ? (isProduction ? 'prod' : 'pre') : 'local', version });
};

export const initLogger = () => {
  initTracking(APPID, {
    autotrack: {
      track_attr: ['data-track-key'],
    },
    disable_auto_pv: true,
    getToken: () => jsCookie.get('token'),
  });
  logBDPage();
  setEnv();
  window.addEventListener('hashchange', () => {
    logBDPage();
  });
  window.addEventListener('historystatechange', () => {
    logBDPage();
  });
  window.addEventListener('popstate', () => {
    logBDPage();
  });
};

initLogger();
