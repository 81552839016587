import { useMemoizedFn } from 'ahooks';
import { logError } from '@/utils/logger/arms-config';
import { useAppSelector } from '@/stores';
import { BasicTrainingCollectionBreakNotice } from '@/modules/BasicTrainingCollection/biz/BasicTrainingCollectionBreakNotice';

/**
 * 
 * @returns {() => Promise<boolean>} shouldPass
 */
export const usePreventRouteChange = (): (() => Promise<boolean>) => {
  const blockers = useAppSelector(state => state.ui.blockers);

  // 是否运行导航变更
  const cb = useMemoizedFn(() => {
    if (blockers.length === 0) {
      return Promise.resolve(true);
    }

    return new Promise<boolean>((resolve, reject) => {
      // 这里虽然数据上支持数组，但是暂时没有处理数组的必要
      if (blockers.includes('basic-training-collection')) {
        BasicTrainingCollectionBreakNotice.open({
          callback: (v: boolean) => {
            resolve(v);
          },
        });
      } else {
        resolve(true);
      }
    }).catch((e) => {
      logError(e);
      return Promise.resolve(true);
    });
  });


  return cb;
};
