import { post } from '@/utils/network/axios-helper';

export interface IGetLessonProgress_Res {
  list: Progress[];
}

export interface Progress {
  id: number;
  /**
   * 已学进度
   */
  seen_duration: number;
  status: number;
  /**
   * 总时长
   */
  total: number;
}

/**
 * 获取单节课，后端section和lesson的概念和前端正好相反
 * @param params 
 * @returns 
 */
export const getLessonRecordInfo = async (params: { id: number[]; }) => {
  return await post<IGetLessonProgress_Res>('/api/course/get-progress', params);
};