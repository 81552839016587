import { CSSProperties } from 'react';
import { EventCenter } from '@/modules/Swift/EventCenter';
import { isNativeDevice } from './common';

export let windowScale = 1;
const min_size = 800;
const max_scale = 1.5;

export const scaleWindow = () => {
  if (EventCenter.isPC() && !isNativeDevice()) {
    const wh = window.screen.height;
    // const wh = window.innerHeight;
    if (wh < min_size) {
      const ih = window.innerHeight;
      const dp = Math.min(min_size / ih, max_scale);
      (document.body.style as CSSProperties).zoom = 1 / dp;
      const v = window.getComputedStyle(document.body).getPropertyValue('zoom')?.toString();
      if (v){
        windowScale = dp;
      }
    }
  }
};

export const getZoom = () => {
  try {
    const v = window.getComputedStyle(document.body).getPropertyValue('zoom')?.toString();
    if (v) {
      return parseFloat(v);
    }
    return windowScale;
  } catch (e) {
    return windowScale;
  }
};

export const scaleDom = (selector: string) => {
  if (EventCenter.isPC()) {
    const wh = window.screen.height;
    const root = document.querySelector(selector) as HTMLDivElement;
    if (wh < min_size && root) {
      const ih = window.innerHeight;
      const dp = Math.min(min_size / ih, max_scale);
      const size = dp * 100 + '%';
      const scale = 1 / dp;
      root.style.transform = `scale(${scale})`;
      root.style.transformOrigin = '0 0';
      root.style.width = size;
      root.style.height = size;
    }
  }
};