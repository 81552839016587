import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, uniq, uniqBy } from 'lodash-es';
import { v4 as UUIDV4 } from 'uuid';
import { logError } from '@/utils/logger/arms-config';
import type { ToolsBarMarkValue } from '@/modules/RichTextEditor/QuillModules/reading-toolsbar/blots/toolsBarMark';
import {
  getPaperInfo,
  readingComprehensionSaveRecord,
  getRecordEssayDraft,
  getRecordInfo,
  getRecordAnswerInfo,
  readingComprehensionCollateAnswer, NoteRef, saveTranslatesApi
} from '@/api/read';
import { getExam } from '@/api/common';


/** 题目类型 */
export enum IRCQuestionTypeEnum {
  /** 判断题 */
  TOF = 1,
  /** 从属配对 */
  DM = 2,
  /** 句子信息配对 */
  SIM = 3,
  /** 总结无选项 */
  SNC = 4,
  /** 单选 */
  MC = 5,
  /** 小标题 */
  ST = 6,
  /** 多选题 */
  MS = 7,
  /** 句子填空 */
  SFI = 8,
  /** 句子配对 */
  SM = 9,
  /** 总结有选项题 */
  SWO = 10,
  /** 简答题 */
  SA = 11,
  /** 图片题 */
  PT = 12,
  /** 表格题 */
  TA = 13,
  /** 自定义多选 */
  CMS = 14
}

export interface IRCQuestionItemOption {
  title: string;
  content: string;
}

export interface IRCQuestionItem {
  id: number;
  title: string;
  number: number;
  option: IRCQuestionItemOption[];
  sub_essay_code: IRCInfoContentTitleKey;
  analyze: any[];
  display_answer: string;
  key_locating_words: any[];
  central_sentences: Array<{
    target_para: number;
    index: number;
    length: number;
    sentence: string; // 英文
    translation: string;
  }>;
  ai_analyze: string;
}

export interface IRCQuestion {
  group_id: number;
  type: IRCQuestionTypeEnum;
  desc: string;
  collect: any;
  collect_option: any;
  list: IRCQuestionItem[];
  img_url?: string;
  table?: {
    title: string;
    content: any[];
  };
}

export type IRCInfoContentTitleKey =
  'pre-A'
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z';


export interface IRCInfoContent extends Partial<Record<IRCInfoContentTitleKey, string>> {
  title?: string;
  sub_title?: string;
  image?: string;
  'pre-A'?: string;
  order_ary?: IRCInfoContentTitleKey[];
  summary: Record<IRCInfoContentTitleKey, string>;
  paragraph?: string[];
}


export interface IRCInfo {
  paper_id: number;
  title: string;
  content: IRCInfoContent;
  topic: string[];
  type: string;
  unit: string;
  question: IRCQuestion[];
  question_number: number[];
  analyze: string;
}

interface IRcQuestionRecordEssayDraft {
  marks: ToolsBarMarkValue[];
  note_content: NoteRef[];
}

export interface NoteContentItem {
  id: string;
  content: string;
  timestamp?: number;
  pid: string;
}

export interface ITranslateItem {
  qid: string;
  selectionIndex: number;
  selectionLength: number;
  selectionText: string;
  translateText: string;
  createTime: number;
  id: string;
  textIndent: number; // px
  blotCharId: string;
  blotIndex: number;
  top: number;
}

export interface IReadingComprehensionState {
  toolBarStatus: boolean;
  /**
   * 阅读理解的详情信息
   */
  rcInfo: IRCInfo | null;

  /**
   * 阅读理解的记录信息
   */
  rcRecordInfo: any;

  /**
   * 阅读理解的答案信息学
   */
  rcRecordAnswerInfo: any;

  /**
   * 是否准备好记录详情
   */
  rcRecordInfoLoading: boolean;

  /**
   * 正文区域
   */
  rcArticleRecordEssayDraft?: IRcQuestionRecordEssayDraft;

  /**
   * 作答区域
   */
  rcQuestionRecordEssayDraft: any;

  /**
   * 是否准备好详情数据
   */
  rcLoading: boolean;

  /**
   * 是否重新获取状态
   */
  rcForceUpdate: number;

  rcScroll: {
    left: {
      top: number;
      left: number;
    };
    right: {
      top: number;
      left: number;
    };
  };
  lastChangeQuillIds: string[];
  notes: NoteContentItem[];

  longTranslates: ITranslateItem[];
  rcNote: any;
}

/**
 * 阅读理解的初始化数据
 */
const initialState: IReadingComprehensionState = {
  toolBarStatus: false,
  rcInfo: null,
  rcRecordInfo: null,
  rcRecordAnswerInfo: null,
  rcRecordInfoLoading: true,
  rcLoading: true,
  rcArticleRecordEssayDraft: undefined,
  rcQuestionRecordEssayDraft: null,
  rcForceUpdate: 0,

  rcScroll: {
    left: {
      top: 0,
      left: 0,
    },
    right: {
      top: 0,
      left: 0,
    }
  },
  // 添加笔记时，最后一次改变的 quill的 id，用于识别没有引用的笔记内容合并到最新的引用中
  lastChangeQuillIds: [],
  notes: [],
  longTranslates: [],
  rcNote: null
};


export interface IReadingComprehensionInfoQuery {
  id: number;
  type?: string;
  record_id?: string;
  question?: string;
}


/**
 * 初始化阅读理解的详情
 */
export const initReadingComprehension = createAsyncThunk('readingComprehension/initReadingComprehension', async (
  params: IReadingComprehensionInfoQuery,
  { rejectWithValue }
) => {
  try {
    const {
      id,
      record_id: record_id_str,
      question: question_str,
      type: type_str,
    } = params;

    // 0. 练习记录 id
    const record_id = record_id_str ? Number(record_id_str) : 0;
    const question = question_str || '';
    const type = type_str ? Number(type_str) : 0;

    // 1. 获取阅读理解的详情
    const info = await getPaperInfo(id, !type ? [] : [type]);
    // 2. 如果是一张新试卷，那么就要先创建一下练习记录
    let recordId = null;
    if (record_id === 0) {
      const res = await readingComprehensionSaveRecord({
        paper_id: id,
        record_id: record_id,
        question: question,
        type: type,
      });
      recordId = res.data.id;
    } else {
      recordId = record_id;
    }
    // 3. 获取联系记录的详情
    const record = await getRecordInfo(recordId);

    if (record.data.paper_id !== id) {
      const s = `当前Record=[${recordId}]属于Paper[${record.data.paper_id}]不属于Paper[${id}]`;
      console.error(s);
      logError(s);
      // TODO: 这里暂时用-1表示资源不匹配，随后统一定义前端错误码
      return rejectWithValue({ code: -1, msg: s });
    }

    const status = record.data.status;

    // 返回值
    let data = {
      rcRecordInfo: info.data,
      recordInfo: record.data,
    } as any;
    // 如果状态是 2 说明已经提交了，那么就要获取答案信息

    if (status === 2) {
      const recordAnswerInfo = await getRecordAnswerInfo(record_id);
      data = {
        ...data,
        recordAnswerInfo: recordAnswerInfo.data
      };
    }

    return data;

  } catch (e) {
    return rejectWithValue({
      code: get(e, 'code'),
      msg: get(e, 'msg'),
    });
  }
});

/**
 * 提交阅读理解的答案
 */
export const submitCollateAnswer = createAsyncThunk('readingComprehension/submitCollateAnswer', async (
  params: any,
  { getState }) => {
  const state = getState() as any;
  const readingComprehension = state.readingComprehension as IReadingComprehensionState;
  const rcRecordInfo = readingComprehension.rcRecordInfo;
  const record_id = rcRecordInfo.id;
  // 提交答案
  await readingComprehensionCollateAnswer({ record_id, list: params });
});

/**
 * 阅读获取草稿信息
 */
export const fetchReadingComprehensionRecordEssayDraft = createAsyncThunk('readingComprehension/fetchReadingComprehensionRecordEssayDraft', async (params: IReadingComprehensionInfoQuery, {
  getState,
  rejectWithValue
}) => {
  const { record_id: record_id_str, type: type_str } = params;
  const record_id = record_id_str ? Number(record_id_str) : 0;
  const type = type_str ? Number(type_str) : 0;

  if (record_id) {
    const res = await getRecordEssayDraft(record_id, type);
    return { type: type, data: res.data };
  } else {
    return null;
  }
});

export const fetchReadingComprehensionExam = createAsyncThunk('readingComprehension/fetchReadingComprehensionExam', async (params: IReadingComprehensionInfoQuery, {
  getState,
  rejectWithValue
}) => {
  const { record_id: record_id_str, type: type_str } = params;
  const record_id = record_id_str ? Number(record_id_str) : 0;
  const type = type_str ? Number(type_str) : 0;
  if (record_id) {
    // 4. 获取笔记缓存
    const res = await getExam({ record_id: record_id, type: 2 });
    return { type: type, data: res.data };
  } else {
    return null;
  }
});

/**
 * 单独保存记录
 */
export const fetchReadingComprehensionSaveRecord = createAsyncThunk('readingComprehension/saveRecord', async (params: string, {
  getState,
}) => {
  const state = getState() as any;
  const { rcRecordInfo } = state.readingComprehension as IReadingComprehensionState;
  const paper_id = rcRecordInfo.paper_id;
  const record_id = rcRecordInfo.id;
  await readingComprehensionSaveRecord({
    paper_id,
    record_id: record_id,
    question: params
  });
});

export const saveLongTranslates = createAsyncThunk('readingComprehension/saveLongTranslates', async (params: string, {
  getState,
}) => {
  const state = getState() as any;
  const { rcRecordInfo, longTranslates } = state.readingComprehension as IReadingComprehensionState;
  const record_id = rcRecordInfo.id as number;

  let isDone = true;

  const list = longTranslates.map((item) => {
    if (!item.translateText) {
      isDone = false;
    }
    return {
      content: item.translateText,
      dict: JSON.stringify({}),
      instance_id: item.qid,
      range: {
        index: item.selectionIndex,
        length: item.selectionLength
      },
      trans_type: 2,
    };
  });

  if (isDone) {
    await saveTranslatesApi({
      record_id: record_id,
      list: list.filter((item) => item.content)
    });
  }
});


/**
 * 阅读理解的部分信息
 */
export const readingComprehensionSlice = createSlice({
  name: 'readingComprehension',
  initialState,
  reducers: {
    reset_reading: (state) => {
      state = initialState;
    },
    forceUpdate: (state) => {
      state.rcForceUpdate = state.rcForceUpdate + 1;
    },
    changeScrollTop: (state, action: {
      payload: {
        isLeft: boolean;
        top: number;
        left: number;
      };
    }) => {
      if (action.payload.isLeft) {
        state.rcScroll.left = {
          left: action.payload.left,
          top: action.payload.top
        };
      } else {
        state.rcScroll.right = {
          left: action.payload.left,
          top: action.payload.top
        };
      }
    },
    addNote: (state, action: {
      payload: {
        note: NoteContentItem;
        allNoteBlotId: string[];
        newBlotId: string;
        lastChangeQuillIds: string[];
      };
    }) => {

      const allBlotId = action.payload.allNoteBlotId;
      state.notes.push(action.payload.note);

      state.notes = uniqBy(state.notes.map((item) => {
        return {
          ...item,
          pid: allBlotId.includes(item.pid) ? item.pid : action.payload.newBlotId,
        };
      }), 'id');

      state.lastChangeQuillIds = uniq(action.payload.lastChangeQuillIds);

    },
    editNote: (state, action: { payload: { note: NoteContentItem; quillIds: string[]; }; }) => {
      state.notes.forEach((item) => {
        if (item.id === action.payload.note.id) {
          item.content = action.payload.note.content;
        }
      });
      state.lastChangeQuillIds = uniq(action.payload.quillIds);
    },
    deleteNote: (state, action: { payload: { noteId: string; notePid: string; quillIds: string[]; }; }) => {
      const pid = action.payload.notePid;
      const newList = state.notes.filter((item) => item.id !== action.payload.noteId);
      state.notes = newList;

      // 如果发现某个blot下已经不存在note了，那么就要删除这个blot
      if (newList.filter((item) => item.pid === pid).length === 0) {
        const elms = document.querySelectorAll<HTMLSpanElement>(`span[data-id="${pid}"]`);
        elms.forEach((item) => {
          if (item.dataset.status) {
            item.dataset.status = 'delete';
          }
        });
      }
      state.lastChangeQuillIds = uniq(action.payload.quillIds);
    },
    addLongTranslate: (state, action: {
      payload: {
        selections: Array<{
          index: number;
          length: number;
          text: string;
          qid?: string;
          id?: string;
        }>;
      };
    }) => {
      const selections = action.payload.selections;
      selections.forEach((item) => {
        const now = Date.now();
        const id = UUIDV4();
        state.longTranslates.push({
          qid: item.qid || '',
          selectionIndex: item.index,
          selectionLength: item.length,
          selectionText: item.text,
          translateText: '',
          createTime: now,
          id: item.id || id,
          textIndent: 0,
          blotCharId: '',
          blotIndex: 0,
          top: 0,
        });
      });
    },
    updateLongTranslateText: (state, action: {
      payload: {
        id: string;
        translateText: string;
      };
    }) => {
      state.longTranslates.forEach((item) => {
        if (item.id === action.payload.id) {
          item.translateText = action.payload.translateText;
        }
      });
    },
    deleteLongTranslate: (state, action: {
      payload: {
        id: string;
      };
    }) => {
      state.longTranslates = state.longTranslates.filter((item) => item.id !== action.payload.id);
    },
    changeToolsBarStatus: (state, action: { payload: { status: boolean; }; }) => {
      state.toolBarStatus = action.payload.status;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initReadingComprehension.pending, (state) => {
        state.rcLoading = true;
      })
      .addCase(initReadingComprehension.fulfilled, (state, action) => {
        if (action.payload) {
          state.rcInfo = action.payload.rcRecordInfo;
          state.rcRecordAnswerInfo = action.payload.recordAnswerInfo || null;
          state.rcRecordInfo = action.payload.recordInfo;
          state.rcLoading = false;
        }
      })
      .addCase(fetchReadingComprehensionRecordEssayDraft.fulfilled, (state, action) => {
        if (action.payload) {
          // 笔记内容全部都放在一起
          const notes: NoteContentItem[] = [];
          (action.payload.data.note_content || []).forEach((item: any) => {
            item.contents?.forEach?.((n: any) => {
              notes.push({
                id: n.local_id,
                content: n.content,
                pid: n.pid,
                timestamp: n.local_timestamp,
              });
            });
          });
          state.notes = [...state.notes, ...uniqBy(notes, 'id')];

          if (action.payload.type === 1) {

            { // 笔记
              const marks: ToolsBarMarkValue[] = (action.payload.data.mark_content || []).map((item: any) => {
                return {
                  index: item.range.index,
                  length: item.range.length,
                  quoteContent: item.quote_content,
                  type: item.flag,
                  id: item.local_id,
                  quillId: item.instance_id,
                  localTimestamp: item.local_timestamp,
                };
              });

              const data = {
                ...action.payload.data,
                marks,
              };
              delete data.mark_content;
              state.rcArticleRecordEssayDraft = data;
            }

            { // 长翻译
              const localId = UUIDV4();
              const longTranslates: ITranslateItem[] = (action.payload.data.translate_content || []).map((item: any, index: number) => {
                return {
                  qid: item.instance_id,
                  selectionIndex: item.range.index,
                  selectionLength: item.range.length,
                  selectionText: '',
                  translateText: item.content,
                  createTime: item.create_time,
                  id: `${localId}-${index}`,
                  textIndent: 0,
                  blotCharId: '',
                  blotIndex: 0,
                  top: 0,
                };
              });
              delete action.payload.data.translate_content;
              state.longTranslates = longTranslates;
            }



          }
          if (action.payload.type === 2) {
            state.rcQuestionRecordEssayDraft = action.payload.data;
          }
        }
      })
      .addCase(submitCollateAnswer.rejected, (state) => {
        state.rcLoading = false;
      })
      .addCase(submitCollateAnswer.pending, (state) => {
        state.rcLoading = true;
      })
      .addCase(submitCollateAnswer.fulfilled, (state) => {
        state.rcForceUpdate = state.rcForceUpdate + 1;
      })
      .addCase(fetchReadingComprehensionExam.fulfilled, (state, action) => {
        state.rcNote = action.payload;
      });
  },
});

export default readingComprehensionSlice;
export const RCActions = readingComprehensionSlice.actions;

