import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITopicConfigItem, ITopicItem, getEssayPaperList, getEssaySearchConfig, getPageListByModule, getTopicSearchConfig } from '@/api/writing/topicList2';


export type ISearchQueryKeys = 'category' | 'topic' | 'year' | 'content' | 'type' | 'subject_type';
export interface ISearchQuery {
  category?: number;
  topic?: number;
  year?: number;
  content?: string;
  type?: number;
  subject_type?: number;
}

export interface ITopicListState {
  loading: boolean;
  loadingConfig: boolean;
  page: number;
  pageSize: number;
  list: ITopicItem[];
  total: number;
  module_id: number;
  searchConfig: ITopicConfigItem[];
  searchEssayConfig: ITopicConfigItem[];
  query: ISearchQuery;
}

const initialState: ITopicListState = {
  loading: true,
  loadingConfig: true,
  page: 1,
  pageSize: 10,
  list: [],
  total: 0,
  module_id: 0,
  searchConfig: [],
  searchEssayConfig: [],
  query: {
    category: 1,
    topic: 0,
    year: 0,
    content: '',
    type: 0,
    subject_type: 0
  },
};

// 获取筛选条件列表
export const getSearchList = createAsyncThunk('topicList2/getSearchList', async (_, {
  getState
}) => {
  const state = (getState() as { topicListSlice2: ITopicListState; }).topicListSlice2 as ITopicListState;

  const params = {
    module_id: state.module_id
  };
  const res = await getTopicSearchConfig(params);
  if (res.code === 200) {
    return res.data.list;
  }
  return [];
});

// 获取题目列表
export const getTopicList = createAsyncThunk('topicList2/getTopicList', async (_, {
  getState,
}) => {
  const state = (getState() as { topicListSlice2: ITopicListState; }).topicListSlice2 as ITopicListState;
  const { year, category, content, topic, subject_type } = state.query;
  const params = {
    page: state.page,
    page_size: state.pageSize,
    module_id: state.module_id,
    year,
    category,
    topic,
    content,
    subject_type
  };
  const info = await getPageListByModule(params);
  if (info.code === 200) {
    return { list: info.data.list, total: info.data.total };
  }
  return { list: [], total: 0 };
});

export const getTopicListInModal = createAsyncThunk('topicList2/getTopicListInModal', async (_, { getState }) => {
  const state = (getState() as { topicListSlice2: ITopicListState; }).topicListSlice2 as ITopicListState;
  const { year, category, content, topic, subject_type } = state.query;
  const params = {
    page: state.page,
    page_size: state.pageSize,
    module_id: state.module_id,
    year,
    category,
    topic,
    content,
    subject_type
  };
  const info = await getPageListByModule(params);
  if (info.code === 200) {
    return { list: info.data.list, total: info.data.total };
  }
  return { list: [], total: 0 };
});


// 获取小作文筛选条件列表
export const getEssaySearchList = createAsyncThunk('topicList2/getEssaySearchList', async () => {
  const res = await getEssaySearchConfig();
  if (res.code === 200) {
    return res.data.list;
  }
  return [];
});

// 获取小作文题目列表
export const getEssayTopicList = createAsyncThunk('topicList2/getEssayTopicList', async (_, {
  getState,
}) => {
  const state = (getState() as { topicListSlice2: ITopicListState; }).topicListSlice2 as ITopicListState;
  const { category, content, type } = state.query;
  const params = {
    page: state.page,
    page_size: 20,
    category,
    content,
    type,
  };
  const info = await getEssayPaperList(params);
  if (info.code === 200) {
    const list: ITopicItem[] = [];
    for (let i = 0; i < info.data.list.length; i++) {
      const item = info.data.list[i];
      list.push({
        content: item.content,
        id: item.id,
        img: item.img_url,
        last_time: item.update_time ? String(item.update_time) : '',
        record_id: item.record_id,
        score: 0,
        status: item.status,
        title: item.title,
        step: 0
      });
    }
    return { list: list, total: info.data.total };
  }
  return { list: [], total: 0 };
});

export const topicListSlice2 = createSlice({
  name: 'topicList2',
  initialState,
  reducers: {
    resetTopic2: (state) => {
      const keys = Object.keys(initialState);
      keys.forEach(key => {
        (state as any)[key] = (initialState as any)[key];
      });
    },
    setActiveModule: (state, action: { payload: number; }) => {
      state.module_id = action.payload;
      state.page = 1;
      state.query = {
        category: 1,
        topic: 0,
        year: 0,
        type: 0,
        subject_type: 0,
        content: ''
      };
      state.list = [];
    },
    setSearchConfigKeys: (state, action: { payload: ISearchQuery; }) => {
      state.query = { ...state.query, ...action.payload };
      // const o = { ...state.query, ...action.payload } as ISearchQuery;
      // const q = {} as ISearchQuery;
      // state.searchConfig.forEach((item, value) =>{
      //   const t = item.key as ISearchQueryKeys;
      //   console.log(item.key,value, o[t]);
      //   const v = o[t];
      //   q[t] = v as never;
      //   console.log(q);
      // });
      if (state.query.category !== 2) {
        state.query.year = 0;
      }
      state.page = 1;
    },
    resetTopicSearch: (state) => {
      state.query = {
        category: 1,
        topic: 0,
        year: 0,
        type: 0,
        subject_type: 0,
        content: ''
      };
      state.page = 1;
    },
    clear: (state) => {
      state.list = [];
      state.total = 0;
      state.loading = false;
      state.page = 1;
      state.module_id = 0;
    },
    getNextPage: (state) => {
      state.page = state.page + 1;
    },
    updatePage: (state, action: { payload: number; }) => {
      state.page = action.payload;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingConfig: (state) => {
      state.loadingConfig = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEssaySearchList.pending, (state) => {
        state.loadingConfig = true;
      })
      .addCase(getSearchList.pending, (state) => {
        state.loadingConfig = true;
      })
      .addCase(getSearchList.fulfilled, (state, action) => {
        state.searchConfig = action.payload;
        state.query = {
          category: 1,
          topic: 0,
          year: 0,
          type: 0,
          subject_type: 0,
          content: state.query.content
        };
        state.loadingConfig = false;
      })
      .addCase(getTopicList.fulfilled, (state, action: { payload: any; }) => {
        // state.list = action.payload.list;
        // state.total = action.payload.total;
        if (action.payload) {
          const newList = state.page === 1 ? action.payload.list : state.list.concat(action.payload.list);
          state.list = newList;

          if (state.page === 1 && action.payload.list.length < state.pageSize && action.payload.list.length < action.payload.total) {
            state.total = action.payload.list.length;
          } else if (state.total !== action.payload.total) {
            state.total = action.payload.total;
          }
        }
        state.loading = false;
      })
      .addCase(getTopicListInModal.fulfilled, (state, action: { payload: any; }) => {

        if (action.payload) {
          state.list = action.payload.list;

          if (state.page === 1 && action.payload.list.length < state.pageSize && action.payload.list.length < action.payload.total) {
            state.total = action.payload.list.length;
          } else if (state.total !== action.payload.total) {
            state.total = action.payload.total;
          }
        }
        state.loading = false;
      })
      .addCase(getEssaySearchList.fulfilled, (state, action) => {
        state.searchEssayConfig = action.payload;
        state.query = {
          category: 1,
          topic: 0,
          year: 0,
          type: 0,
          content: state.query.content
        };
        state.loadingConfig = false;
      })
      .addCase(getEssayTopicList.fulfilled, (state, action) => {
        if (action.payload) {
          const newList = state.page === 1 ? action.payload.list : state.list.concat(action.payload.list);
          state.list = newList;
          if (state.total !== action.payload.total) {
            state.total = action.payload.total;
          }
        }
        state.loading = false;
      });
  },
});

export const topicListActions = topicListSlice2.actions;

