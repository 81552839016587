/**
 * 历史记录相关
 */

import { post } from '@/utils/network/axios-helper';

/***********************************************************************************************************************/

export interface IGetWritingTask2SampleEssayList_Request {
  page: number;
  page_size: number;
  paper_id?: number;
  content?: string;
}

export interface IWritingTask2SampleEssay {
  id: number;

  /** 生成的范文内容 */
  content: string;
  /** 用户写的思路 */
  thought: string;
  /** 用户填写的定制需求 */
  requirement: string;

  /** 1 无范文 2 有范文 */
  status: number;
  update_time: number;

  // 附加试卷信息

  /** paper id */
  paper_id: number;
  /** paper 标题 如：剑雅18-1 */
  paper_title: string;
  /** 题目内容 */
  paper_content: string;
  /** 话题分类 */
  paper_topic: string;
}

export interface IGetWritingTask2SampleEssayList_Response {
  list: IWritingTask2SampleEssay[];
  total: number;
}

/**
 * 我的范文记录列表
 * @param param IGetWritingTask2SampleEssayList_Request
 * @returns
 */
export const getWritingTask2SampleEssayList = (param: IGetWritingTask2SampleEssayList_Request) => {
  return post<IGetWritingTask2SampleEssayList_Response>('/api/exercise/get-big-essay-sample-text-list', param);
};

/***********************************************************************************************************************/

export interface IGetWritingTask2EvaluationList_Request {
  page: number;
  page_size: number;
  content?: string;
  id?: number[];
}

export interface IWritingTask2Evaluation {
  id: number;
  content: string;
  score: number;
  /** 1 无评分 2 已完成，评分未结束 3 有评分 */
  status: number;
  create_time: number;
  update_time: number;
  paper_id: number;
  paper_title: string;
  paper_content: string;

  score_file?: string;
}

export interface IGetWritingTask2EvaluationList_Response {
  list: IWritingTask2Evaluation[];
  total: number;
}

/**
 * 写作task2评分列表
 * @param param 
 * @returns 
 */
export const getWritingTask2EvaluationList = (param: IGetWritingTask2EvaluationList_Request) => {
  return post<IGetWritingTask2EvaluationList_Response>('/api/exercise/get-big-essay-record-list', param);
};

/***********************************************************************************************************************/

export interface IGetWritingTask1EvaluationList_Request {
  page: number;
  page_size: number;
  content?: string;
  id?: number[];
}

export interface IWritingTask1Evaluation {
  id: number;
  content: string;
  score: number;
  /** 1 无评分 2 已完成，评分未结束 3 有评分 */
  status: number;
  create_time: number;
  update_time: number;
  paper_id: number;
  paper_title: string;
  paper_content: string;
  score_file?: string;
}

export interface IGetWritingTask1EvaluationList_Response {
  list: IWritingTask1Evaluation[];
  total: number;
}

/**
 * 写作task1评分列表
 * @param param 
 * @returns 
 */
export const getWritingTask1EvaluationList = (param: IGetWritingTask1EvaluationList_Request) => {
  return post<IGetWritingTask1EvaluationList_Response>('/api/exercise/get-essay-record-list', param);
};

/***********************************************************************************************************************/

export interface IGetWritingTask2PracticeList_Request {
  page: number;
  page_size: number;
}

export interface IWritingTask2Practice {
  step: number;
  id: number;
  paper_content: string;
  paper_id: number;
  paper_title: string;
  record_id: number;
  /**
   * 练习类型：1审题练习 2思路拓展练习 3分段练习 4整篇练习
   */
  type: number;
  update_time: number;
}

export interface IGetWritingTask2PracticeList_Response {
  list: IWritingTask2Practice[];
  total: number;
}

export const getWritingTask2PracticeList = (param: IGetWritingTask2PracticeList_Request) => {
  return post<IGetWritingTask2PracticeList_Response>('/api/exercise/get-practice-record-list', param);
};

export const getWritingTask2PracticeList_1 = async (param: IGetWritingTask2PracticeList_Request) => {
  const a = await getWritingTask2PracticeList(param);
  if (a.code === 200) {
    a.data.list.forEach((v) => {
      v.record_id = v.id;
      v.id = Number(v.paper_id + '' + v.id);
    });
  }
  return a;
};


export interface IMockTask2SampleEssay {
  id: number;
}

export interface IGetMockTask2PracticeList_Request {
  page: number;
  page_size: number;

}

export interface IGetMockTask2PracticeList_Response {
  list: IMockTask2Practice[];
  total: number;
  currentPage: number;
  pageSize: number;
}


export interface IMockTask2PracticeChild {
  paper_id: number;
  course: string;
  title: string;
  record_id: number;
  status: number;
  score: number;
  update_time: number;
}

export interface IMockTask2Practice {
  id: number;
  paper_group_id: number;
  title: string;
  type: number;
  update_time: number;
  children: IMockTask2PracticeChild[];
}

export const getMockTask2PracticeList = async (param: IGetMockTask2PracticeList_Request) => {
  return post<IGetMockTask2PracticeList_Response>('/api/simulate/get-record-list', param);
};


export interface IGetReadingRecordListResponse {
  list: IReadingRecordItem[];
  total: number;
}
export interface IReadingRecordItem {
  content: string;
  correct_num: number;
  create_time: number;
  done_num: number;
  id: number;
  paper_id: number;
  status: number;
  title: string;
  total_num: number;
  type: string;
}
export const getReadingRecordList = (param: IGetWritingTask2PracticeList_Request) => {
  return post<IGetReadingRecordListResponse>('/api/reading/v1/get-record-list', { ...param });
};
export const getListeningRecordList = (param: IGetWritingTask2PracticeList_Request) => {
  return post<IGetReadingRecordListResponse>('/api/listening/v1/get-record-list', { ...param });
};

export const getLastMockTestScore = () => {
  return post<{ score: number; }>('/api/exam/get-simulate-exam-last-score', {});
};

export interface IGetTrainingListRequest {
  page: number;
  page_size: number;
  course: number;
}
export interface ITrainingListResponse {
  list: ITrainingRecordItem[];
  total: number;
}
export interface ITrainingRecordItem {
  id: number;
  record_id: number;
  question_id: number;
  sub_answer: string;
  status: number;
  result: number;
  create_time: string;
  title: string;
  result_type: number;
  schedule: string;
  score: number;
  type: number;
  type_text: string;
  course: number;
  group_id: number;
  version: number;
}

export const getTrainingRecordList = async (param: IGetTrainingListRequest) => {
  const a = await post<ITrainingListResponse>('/api/basic-training/get-record-list', { ...param });
  if (a.code === 200) {
    a.data.list.forEach((v) => {
      v.course = param.course;
    });
  }
  return a;
};
export const getTrainingWRecordList = (param: IGetMockTask2PracticeList_Request) => {
  return getTrainingRecordList({ course: 1, ...param });
};
export const getTrainingRRecordList = (param: IGetMockTask2PracticeList_Request) => {
  return getTrainingRecordList({ course: 2, ...param });
};
export const getTrainingLRecordList = (param: IGetMockTask2PracticeList_Request) => {
  return getTrainingRecordList({ course: 3, ...param });
};


export interface ICollectionListResponse {
  list: ICollectionRecordItem[];
  total: number;
}
export interface ICollectionRecordItem {
  collection_name: string;
  collection_id: number;
  correct: number;
  difficulty: string;
  end_time: number;
  grammar: string;
  id: number;
  questionType: string;
  topic: string;
  total: number;
  use_time: number;
  course: number;
  rate: string;
}

export interface IGetCollectionListRequest {
  page: number;
  page_size: number;
  course: number;
}

export const getCollectionRecordList = async (param: IGetCollectionListRequest) => {
  const a = await post<ICollectionListResponse>('/api/exam/get-collection-all-record-list', { ...param });
  if (a.code === 200) {
    a.data.list.forEach((v) => {
      v.course = param.course;
    });
  }
  return a;
};
export const getCollectionWRecordList = (param: IGetMockTask2PracticeList_Request) => {
  return getCollectionRecordList({ course: 1, ...param });
};
export const getCollectionRRecordList = (param: IGetMockTask2PracticeList_Request) => {
  return getCollectionRecordList({ course: 2, ...param });
};
export const getCollectionLRecordList = (param: IGetMockTask2PracticeList_Request) => {
  return getCollectionRecordList({ course: 3, ...param });
};
