import { createAsyncThunk, createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '@/utils/persist/local-storage';
import { getMenuList } from '@/api/common';


const CardZoomKey = 'BasicTrainingCardZoom';

export interface IBreadCrumb {
  title?: string;
  href?: string;
}

export interface IUIStateType {
  breadcrumbs: IBreadCrumb[];
  blockers: string[];
  home: {
    /** 三段布局 */
    menu: any[];
    flattenMenu: any[];
    showMenu: boolean;
    showRight: boolean;

    /** 返回按钮 */
    backUrl: string;
    rightButton: string[];

    is_replace_header_force_update: number;

    /** 完全通过自定义的钩子替换现有的子组件 */
    is_replace_header_children: boolean;
    /** 使用插槽替换子组件 */
    is_replace_header_slot: any;

    /** 仅使用自定义 left 部分替换现有的子组件 */
    is_replace_header_left_children: boolean;
    /** 使用插槽替换子组件 */
    is_replace_header_left_slot: any;

  };
  basicTraining: {
    // 卡片缩放
    cardZoom: number;
    showRight: boolean;
  };
  course: {
    showSideBar: boolean;
    showRight: boolean;
    showSwitchButton: boolean;
    rightButton: string[];
  };
}

const initialState: IUIStateType = {
  breadcrumbs: [],
  blockers: [],
  home: {
    showMenu: true,
    showRight: false,
    menu: [],
    flattenMenu: [],
    backUrl: '',
    rightButton: [],
    is_replace_header_force_update: 0,
    is_replace_header_children: false,
    is_replace_header_slot: null,
    is_replace_header_left_children: false,
    is_replace_header_left_slot: null,

  },
  basicTraining: {
    cardZoom: LocalStorage.getStorage(CardZoomKey) || 2,
    showRight: false,
  },
  course: {
    showSideBar: true,
    showRight: false,
    showSwitchButton: false,
    rightButton: [],
  },

};

export const fetchMenus = createAsyncThunk('ui/fetchMenus', async (_, { rejectWithValue }) => {
  try {
    const res = await getMenuList();
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }
    return {
      menu: res.data.list,
    };
  } catch (e) {
    console.error('fetchUserMenus error', e);
  }
});

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setBreadCrumbs: (state, action: { payload: IBreadCrumb[]; }) => {
      state.breadcrumbs = action.payload;
    },

    addBlocker: (state, action: { payload: string; }) => {
      if (!state.blockers.includes(action.payload)) {
        state.blockers.push(action.payload);
      }
    },

    removeBlocker: (state, action: { payload: string; }) => {
      if (state.blockers.includes(action.payload)) {
        state.blockers = state.blockers.filter((ele) => ele !== action.payload);
      }
    },

    /** home */
    setHomeBackUrl: (state, action: { payload: string; }) => {
      state.home.backUrl = action.payload;
    },
    setHomeMenuVisible: (state, action: { payload: boolean; }) => {
      state.home.showMenu = !!action.payload;

      if (action.payload) {
        state.home.showRight = !action.payload;

        state.basicTraining.showRight = !action.payload;
      }
    },
    setHomeRightButton: (state, action: { payload: string[]; }) => {
      state.home.rightButton = action.payload;
    },

    /** 是否要替换 header 内容 */
    set_is_replace_header_children: (state, action) => {
      state.home.is_replace_header_children = action.payload;
    },
    /** 用插槽替换 header 内容 */
    set_is_replace_header_slot: (state, action) => {
      state.home.is_replace_header_slot = action.payload;
    },

    /** 是否要替换左边部分 */
    set_is_replace_header_left_children: (state, action) => {
      state.home.is_replace_header_left_children = action.payload;
    },
    /** 使用插槽替换 left 内容 */
    set_is_replace_header_left_slot: (state, action) => {
      state.home.is_replace_header_left_slot = action.payload;
    },

    /** 强制渲染 */
    set_is_replace_header_force_update: (state, action) => {
      state.home.is_replace_header_force_update = action.payload;
    },

    /** basic training */
    setBasicTrainingCardZoom: (state, action: { payload: number; }) => {
      state.basicTraining.cardZoom = action.payload;
    },
    setBasicTrainingShowAI: (state, action: { payload: boolean; }) => {
      state.basicTraining.showRight = !!action.payload;

      state.home.showRight = !!action.payload;
      state.course.showRight = !!action.payload;
      if (action.payload) {
        state.home.showMenu = !action.payload;
        state.course.showSideBar = !action.payload;
      }
    },

    setCourseSideBar: (state, action: { payload: boolean; }) => {
      state.course.showSideBar = !!action.payload;

      if (action.payload) {
        state.course.showRight = !action.payload;
      }
    },
    setCourseShowRight: (state, action: { payload: boolean; }) => {
      state.course.showRight = !!action.payload;
      if (action.payload) {
        state.course.showSideBar = !action.payload;
      }
    },
    setCourseRightButton: (state, action: { payload: string[]; }) => {
      state.course.rightButton = action.payload;
    },
    setCourseShowSwitch: (state, action: { payload: boolean; }) => {
      state.course.showSwitchButton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenus.fulfilled, (state, action) => {
        if (action.payload) {
          const list: any[] = [];
          const menus = action.payload.menu || [];
          const flattenMenu = (menus: any[]) => {
            menus.forEach(item => {
              list.push(item);
              if (item.id === 28) {
                item.module_path = '/training-collection';
              }
              if (item.children) {
                flattenMenu(item.children);
              }
            });
          };
          flattenMenu(menus);
          state.home.menu = action.payload.menu;
          state.home.flattenMenu = list;
        }
      });
  },
});

export const uiSliceMiddleWare = createListenerMiddleware();
uiSliceMiddleWare.startListening({
  predicate: (action, currentState, originalState) => {
    return action.type === 'ui/setBasicTrainingCardZoom';
  },
  effect: (action, listenerApi) => {
    const state = listenerApi.getState();
    if (typeof (state as any)?.ui?.basicTraining.cardZoom === 'number') {
      LocalStorage.setStorage(CardZoomKey, (state as any).ui.basicTraining.cardZoom);
    }

  },
});


export const { setBreadCrumbs, setBasicTrainingCardZoom } = uiSlice.actions;

export default uiSlice;

export const UIAction = uiSlice.actions;
