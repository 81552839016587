
class HomeSlotMap {
  public slots: Map<string, any>;
  constructor() {
    this.slots = new Map();
  }
  get(key: string) {
    return this.slots.get(key);
  }
  set(key: string, value: any) {
    this.slots.set(key, value);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new HomeSlotMap();