import React, { useEffect } from 'react';
import { isMobile, isNative } from '@/utils/platform';
import { gotoH5Page } from '@/utils/gotoH5Page';
import i18n from '@/locales/init';

export const withMobileFallbackHOC = (Outlet: (props: { [key: string]: any; }) => JSX.Element) => {

  return React.memo((props: { [key: string]: any; }) => {
    useEffect(() => {
      if (isMobile() && !isNative()) {
        const rootEle = document.getElementById('root');
        if (rootEle) {
          rootEle.style.minHeight = 'unset';
          rootEle.style.minWidth = 'unset';
        }
      }
    }, []);

    if (isMobile() && !isNative()) {
      if (gotoH5Page()){
        return '';
      }
      // return (
      //   <div style={{
      //     width: '100vw',
      //     height: '100vh',
      //     position: 'absolute',
      //     fontSize: '2em',
      //     textAlign: 'center',
      //     display: 'flex',
      //     flexDirection: 'column',
      //     justifyContent: 'center',
      //   }}>
      //     <div>{i18n.t('common.dauyan_account_fallback_not_support_phone')}</div>
      //     <div>{i18n.t('common.dauyan_account_fallback_goto_pc')}</div>
      //   </div>
      // );
    }
    return <Outlet {...props} />;
  });
};

