import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Menu as AntdMenu, Spin } from 'antd';
import { cloneDeep } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { SimpleIconFont } from '@/components/Simple/SimpleIconFont';
import { ieltsWeb } from '@/utils/env';
import { logInfo } from '@/utils/logger/arms-config';
import { logBDBeconEvent } from '@/utils/logger/bd-tracker';
import { appDispatch, useAppSelector } from '@/stores';
import { IBreadCrumb, setBreadCrumbs } from '@/stores/slices/uiSlice';
import { usePreventRouteChange } from '@/hooks/usePreventRouteChange';
import css from '../layout.module.less';
import style from './index.module.less';

export interface MenuProps {
  showMenu?: boolean;
  display?: number;
}

type MenuItem = any;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export const Menu: React.FC<MenuProps> = (props) => {
  const menu = useAppSelector(state => state.ui.home.menu);
  const flattenMenu = useAppSelector(state => state.ui.home.flattenMenu);
  const module_id = useAppSelector(store => store.topicListSlice2.module_id);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]); // 默认展开项
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]); // 默认选中的项
  const showMenu = useAppSelector(state => state.ui.home.showMenu);
  const location = useLocation();
  const navigate = useNavigate();

  const include_key = ['children', 'icon', 'key', 'label'];

  // 将数据处理成渲染需要的格式
  const recursiveProcessMenu = (list: any[], layer = 0) => {
    list.forEach(item => {
      const hasChildren = item.children && item.children.length > 0;
      item.key = item.id;
      item.label = <span className={style[`menu-layer${layer}`]}>{item.name}<style>
        {`#${item.icon} path {fill: inherit}`}
      </style></span>;
      if (item.icon) {
        item.icon = <SimpleIconFont type={item.icon} />;
      }
      if (hasChildren) {
        item.disabled = true;
        recursiveProcessMenu(item.children, layer + 1);
      }
      const keys = Object.keys(item);
      keys.forEach(key => {
        if (!include_key.includes(key)) {
          delete item[key];
        }
      });
    });
    return list;
  };

  // 根据 pathname 找到对应的菜单项目
  const findMenuItem = (location: any) => {
    const pathname = location.pathname as string;
    // 找到当前的
    const select = flattenMenu.find(d => {
      if (d.module_path) {
        return d.module_path !== '/' && (pathname.includes(`${d.module_path}`) || d.module_path === pathname);
      } else if (d.id === 1 && pathname === '/') {
        return true;
      } else {
        return null;
      }
    });
    if (select) {
      // 找到它所有的父亲
      let current = select;
      let doing = true;
      const openKeys: any[] = [];
      while (doing) {
        // eslint-disable-next-line no-loop-func
        const pItem = flattenMenu.find(d => d.id === current.pid);
        if (pItem) {
          openKeys.push(pItem);
          current = pItem;
        } else {
          doing = false;
        }
      }
      return {
        selectedKeys: [`${select.id}`],
        openKeys: openKeys.map(d => `${d.id}`)
      };
    } else {
      return {
        selectedKeys: [],
        openKeys: []
      };
    }
  };

  // 设置默认展开的菜单
  const setSelectedMenu = useCallback(() => {
    if (flattenMenu.length > 0) {
      const { selectedKeys, openKeys } = findMenuItem(location);
      appDispatch(setBreadCrumbs(getBreads([...openKeys, ...selectedKeys])));
      setDefaultSelectedKeys(selectedKeys);
    }
  }, [module_id, menu, flattenMenu, location]);


  const getBreads = (list: string[]) => {
    const result: IBreadCrumb[] = [];
    list.forEach(item => {
      const n = Number(item);
      const i = flattenMenu.find(v => v.id === n);
      if (i) {
        result.push({
          title: i.name,
        });
      }
    });
    return result;
  };

  const blocker = usePreventRouteChange();

  // 选中了某一个菜单项
  const handleSelectedNavItem = useCallback((payload: any) => {
    logBDBeconEvent('ielts_menu_click', { module_key: `${payload.key}` });
    const next = flattenMenu.find(d => `${d.id}` === `${payload.key}`);
    const module_path = next.module_path;
    logInfo({ from: defaultSelectedKeys, to: module_path });
    if (next.pid === 33) {
      // 课程在新页面打开
      const u = `${ieltsWeb}course/#${module_path}`;
      window.open(u);
    } else {
      blocker().then((v) => {
        if (v) {
          setDefaultSelectedKeys([`${payload.key}`]);
          navigate(module_path);
        }
      });
    }
  }, [defaultSelectedKeys, flattenMenu]);

  const items = useMemo(() => {
    return recursiveProcessMenu(cloneDeep(menu));
  }, [menu]);

  useEffect(() => {
    setDefaultOpenKeys(menu.map(item => `${item.id}`));
  }, [menu]);

  useEffect(() => {
    setSelectedMenu();
  }, [setSelectedMenu]);

  const menu_component = () => {
    if (defaultOpenKeys.length > 0 && props.display === 0) {
      return (
        <AntdMenu
          mode="inline"
          theme="light"
          inlineIndent={16}
          selectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          items={items}
          onSelect={(payload) => {
            handleSelectedNavItem(payload);
          }}
        />
      );
    }

    if (defaultOpenKeys.length > 0 && props.display === 1) {
      return (
        <AntdMenu
          mode="inline"
          theme="light"
          inlineIndent={16}
          selectedKeys={defaultSelectedKeys}
          items={items}
          onSelect={(payload) => {
            handleSelectedNavItem(payload);
          }}
        />
      );
    }
  };

  return (
    <div className={classnames(css['home-menu'])} >
      {menu_component()}
    </div>
  );
};