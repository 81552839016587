import React from 'react';
import classnames from 'classnames';
import { Skeleton } from 'antd';
import { SimpleImagePro } from '@/components/Simple/SimpleImagePro';
import css from './index.module.less';

export interface LmsSectionProps {
  idx: number;
  item: any;
}

const infos = {
  53: ['了解写作评分规则', '掌握写作话题思路', '提升写作表达能力'],
  54: ['积累听力场景常用词汇', '提升听力快速反应能力', '学会听力精准定位信息'],
  57: ['了解口语评分标准', '构建口语表达框架', '积累词汇句式素材'],
  59: ['掌握阅读高效解题方法', '提升长难句式理解能力', '突破阅读时间限制难题'],
};

export const LmsSection = (props: LmsSectionProps) => {

  const id = props.item?.id || 0;

  return props.item ? (
    <div className={classnames(css[`section_bg_0${props.idx}`], css['section_item'])} onClick={() => {
      window.open(window.location.origin + `/course/#/course/${props.item.id}`);
    }}>

      <div className={classnames(css['section_img'])}>
        <SimpleImagePro width={'100%'} src={props.item?.cover_path || ''} />
      </div>

      <div className={classnames(css['section_title'])}>{props.item?.name}</div>

      <div className={classnames(css['section_content'])}>
        {id && ((infos as any)[id] || []).map((d: any, idx: number) => {
          return (
            <div key={idx} className={classnames(css['section_content_item'])}>{d}</div>
          );
        })}
      </div>
    </div>
  ) :
    <div className={classnames(css['section_item'])}>
      <Skeleton />
    </div>;
};