import { post } from '@/utils/network/axios-helper';

import type { LessonType } from '@/modules/Course/course.types';


export interface ICourseInfo_Res {
  detail: Detail;
  lesson_list: LessonList[];
  progress: Progress;
}

export interface Detail {
  cover_path: string;
  details: string;
  /**
   * 已学人数
   */
  done_num: number;
  /**
   * 点击人次
   */
  click_num: number;
  id: number;
  introduction: string;
  /**
   * 1未购买 2已购买
   */
  is_purchase: number;
  name: string;
  original_price: number;
  price: number;
  /**
   * 跳转链接
   */
  url: string;
}

export interface LessonList {
  lesson_id: number;
  name: string;
  section_list: SectionList[];
}

export interface SectionList {
  /**
   * 1免费 2付费
   */
  is_free: number;
  name: string;
  /**
   * 小节id
   */
  section_id: number;
  /**
   * 小节类型
   * 视频课 = 1,
   * 基础练习 = 2,
   * 强化练习-写作 = 3,
   * 强化练习-听力 = 4,
   * 强化练习-阅读 = 5
   * 口语-进阶练习 = 6
   */
  exam_type: LessonType;

  // 基础练习
  question_num: number;


  part_name: string;
  topic_name: string;
}

export interface Progress {
  finished: number;
  /**
   * 最后学习小结
   */
  section_id: number;
  total: number;
  study_num: number;
}

export interface IGetCourseInfo_Req {
  id: number;
}

export const getCourseInfo = async (data: IGetCourseInfo_Req) => {
  return post<ICourseInfo_Res>('/api/course/get-course-info', data);
};